import React from "react";
import { Layout } from "antd";
import IdleTimer from "react-idle-timer";

import Header from "../../components/Header";
import Breadcrumbs from "../../components/Breadcrumbs";
import PageTitle from "../../components/PageTitle";
import PageContent from "../../components/PageContent";
import config from "../../utils/config";
import SideMenuContainer from "../../components/SideMenu/SideMenuContainer";

const MasterLayout = (props: any) => {
  const idleTimer = React.createRef() as any;
    const isIframe = window.self !== window.top;
    return (
    <Layout className={"yjMainLayout"} style={{ height: "100vh" }}>
      <React.Fragment>
        <IdleTimer
          key="idleTimer"
          element={document}
          startOnMount={true}
          ref={idleTimer}
          onIdle={(e: Event) => {}}
          onActive={(e: Event) => {}}
          timeout={config.idleTime}
        />
      </React.Fragment>
    {!isIframe && <Header onChangeLanguage={(key: any) => {}} {...props} />}
      <Layout>
          {<SideMenuContainer menuConfig={props.menus} />}
        <Layout className={"yjMasterLayoutWrapper"}>
          <Breadcrumbs routes={props.routes} />
          {props.children}
        </Layout>
      </Layout>
    </Layout>
  );
};

export { PageTitle, PageContent };
export default MasterLayout;
