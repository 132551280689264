import React, { useState, useEffect } from "react";
import { Layout, Menu, Dropdown, Select } from "antd";
import { LogoutOutlined, GlobalOutlined } from "@ant-design/icons";
import styles from "./index.module.less";
import { useOktaAuth } from "@okta/okta-react";
import UserImg from "../../../styles/assets/images/userLogo.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@app/redux/reducers/state";
import { SET_TENANT } from "@app/redux/actionTypes/authActionTypes";
import { getFirmNameAndLogo } from "@app/api/commonService";
import { setFirmNameAndLogo } from "@app/redux/actions/firmActions";
import { Link } from "react-router-dom";

const { Header } = Layout;

export default (props: any) => {
  const [language, setLanguage] = useState("EN");
  const [tids, setTids] = useState<Array<string>>([]);
  const { tenant, user } = useSelector(
    (rootState: RootState) => rootState.auth
  );
  const { firmLogo, firmName } = useSelector(
    (rootState: RootState) => rootState.firm
  );
  const firmBase64Logo = `data:image/png;base64, ${firmLogo}`
  const { oktaAuth, authState } = useOktaAuth();
  const dispatch = useDispatch();

  const handleOnLanguageChange = (e: any) => {
    props.onChangeLanguage(e);
  };

  const resetLocaleLabel = () => {
    const lang = localStorage.getItem("locale");
    if (lang) {
      const locale = lang === "en" ? "EN" : "ES";
      setLanguage(locale);
    } else {
      setLanguage("EN");
    }
  };

  useEffect(() => {
    resetLocaleLabel();
  });

  useEffect(() => {
    if (tenant)
      getFirmNameAndLogo().then((data) => {
        dispatch(setFirmNameAndLogo(data.data));
      });
  }, [tenant]);

  const menu = (
    <Menu
      data-testid="optionEn"
      onClick={(e: any) => {
        setLanguage(e.key === "en" ? "EN" : "ES");
        handleOnLanguageChange(e);
      }}
    >
      <Menu.Item key="en" disabled={true}>
        English
      </Menu.Item>
      <Menu.Item key="es" disabled={true}>
        Espanol
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (!props.hideLogout) {
      setTids((authState?.accessToken?.claims.tid as Array<string>) ?? []);
    }
  }, [authState]);

  const onSelect = (val: string) => {
    dispatch({ type: SET_TENANT, payload: val });
  };

  const logout = () => {
    oktaAuth.signOut();
    localStorage.clear();
  };

  return (
    <Header className={styles.yjHeader}>
      {firmBase64Logo ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            onClick={() => props.history.push("/dashboard")}
            className={styles.yjFirmLogo}
            style={{
              backgroundSize: "height",
            }}
            alt={firmName}
            src={firmBase64Logo}
            loading="lazy"
          />
          <div
            onClick={() => props.history.push("/dashboard")}
            className={styles.yjFirmName}
          >
            {firmName}
          </div>
        </div>
      ) : (
        <div
          onClick={() => props.history.push("/dashboard")}
          className={styles.yjAppLogo}
        ></div>
      )}
      {!props.hideLogout && (
        <Menu mode="horizontal" className={styles.yjHeaderMenu}>
          <Menu.Item key="3">
            <Dropdown overlay={menu} placement="bottomCenter">
              <a href="#drop-down" onClick={(e) => e.preventDefault()}>
                <GlobalOutlined />
              </a>
            </Dropdown>

            <span className={styles.yjLanTag}>{language}</span>
          </Menu.Item>
          <Menu.SubMenu
            className={styles.yjHeaderUserName}
            title={user?.name ?? ""}
            key="1"
            icon={<img src={UserImg} width="30px" />}
          >
            {tids.length > 1 && (
              <div style={{ padding: "10px" }}>
                Tenant :{" "}
                <Select
                  defaultValue={tenant ?? ""}
                  onChange={onSelect}
                  style={{ pointerEvents: "auto", width: "80px" }}
                >
                  {tids.map((e) => (
                    <Select.Option value={e}>{e}</Select.Option>
                  ))}
                </Select>
              </div>
            )}

            <Menu.Item
              title="Logout"
              onClick={logout}
              key="2"
              icon={<LogoutOutlined />}
            >
              Logout
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
      )}
    </Header>
  );
};
