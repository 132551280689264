import React from "react";

//this hook can be used to access the state value through a reference
export default (defaultValue: any) => {
  var [state, setState] = React.useState(defaultValue);
  var ref = React.useRef(state);

  var dispatch = React.useCallback(function (val) {
    ref.current = typeof val === "function" ? val(ref.current) : val;
    setState(ref.current);
  }, []);

  return [state, dispatch, ref];
};
