import { SET_DYNAMIC_BREADCRUMS } from "../actionTypes/configurationActionTypes";
import { ConfigState } from "../types/ConfigState";

const initialState: ConfigState = { dynamicBreadcrumValues: [] };

export const configurationReducer = (state = initialState, action: any) => {
  if (action.type === SET_DYNAMIC_BREADCRUMS) {
    return { ...state, dynamicBreadcrumValues: action.payload };
  } else {
    return state;
  }
};
