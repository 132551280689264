import { SET_TEMPLATES } from "@app/redux/actionTypes/templateActionType";
export interface ITemplates {
  activeTemplates: [];
}

export const initialState: ITemplates = {
  activeTemplates: [],
};

export const templateReducer = (state = initialState, action: any) => {
  if (action.type === SET_TEMPLATES) {
    return { ...state, activeTemplates: action.payload };
  } else {
    return state;
  }
};
