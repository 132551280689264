export const SET_FOLDER_TREE = "@@FILE_AREA/SET_FOLDER_TREE";
export const SET_FILE_AREA_SETTINGS = "@@FILE_AREA/SET_FILE_AREA_SETTINGS";
export const SET_HAS_COMMON_DATA = "@@FILE_AREA/SET_HAS_COMMON_DATA";
export const UPDATE_CONTEXT_MENU_DOWNLOAD =
  "@@FILE_AREA/UPDATE_CONTEXT_MENU_DOWNLOAD";

export const UPDATE_CONTEXT_MENU_CHECKOUT =
  "@@FILE_AREA/UPDATE_CONTEXT_MENU_CHECKOUT";

export const UPDATE_CONTEXT_MENU_PUBLISH =
  "@@FILE_AREA/UPDATE_CONTEXT_MENU_PUBLISH";

export const UPDATE_CONTEXT_MENU_UNPUBLISH =
  "@@FILE_AREA/UPDATE_CONTEXT_MENU_UNPUBLISH";

export const UPDATE_LOAD_GRID = "@@FILE_AREA/UPDATE_LOAD_GRID";

export const UPDATE_CONTEXT_MENU_STATUS =
  "@@FILE_AREA/UPDATE_CONTEXT_MENU_STATUS";

export const UPDATE_CONTEXT_MENU_ASSIGN =
  "@@FILE_AREA/UPDATE_CONTEXT_MENU_ASSIGN";

export const UPDATE_CONTEXT_MENU_DELETE =
  "@@FILE_AREA/UPDATE_CONTEXT_MENU_DELETE";

export const UPDATE_CONTEXT_MENU_PROPETIES =
  "@@FILE_AREA/UPDATE_CONTEXT_MENU_PROPETIES";

export const UPDATE_CONTEXT_MENU_RE_CATEGORIZE =
  "@@FILE_AREA/UPDATE_CONTEXT_MENU_RE_CATEGORIZE";

export const FILES_UPLOADED = "@@FILE_AREA/FILES_UPLOADED";

export const UPDATE_ALLOWED_TO_CLOSE_PROPERTIES =
  "@@FILE_AREA/UPDATE_ALLOWED_TO_CLOSE_PROPERTIES";
export const UPDATE_PORTAL_FILE_UPLOAD_DETAILS =
  "@@FILE_AREA/UPDATE_PORTAL_FILE_UPLOAD_DETAILS";

export const PORTAL_FILE_REQUEST_SENT = "@@FILE_AREA/PORTAL_FILE_REQUEST_SENT";

export const PORTAL_FILE_UPDATE_SELECTED_REQUEST =
  "@@FILE_AREA/PORTAL_FILE_UPDATE_SELECTED_REQUEST";
