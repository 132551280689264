import MenuConfigType from "@app/types/MenuConfigType";
import { MenuItem } from "@app/redux/types/authTypes/auth";

const mapMenuConfig = (menuConfig: MenuConfigType[], menuItems: MenuItem[]): MenuConfigType[] => {
    return menuConfig.map((item) => {
        const foundItem = menuItems.find(val => val.key === item.key);
        if(foundItem) {
            return {
                ...item,
                privileged: true,
                title: foundItem.name,
                order: foundItem.order,
                children: item.children ? mapMenuConfig(item.children, foundItem.subMenus) : []
            }
        } else {
            return {
                ...item,
                privileged: false
            }
        }
    });
}

export default mapMenuConfig;

