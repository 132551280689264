import MenuConfigType from "@app/types/MenuConfigType";
import {
  ApartmentOutlined,
  AppstoreOutlined,
  ControlOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";

const masterDataMenuConfig: MenuConfigType = {
  path: "/master-data/",
  title: "Master Data",
  key: "MasterData",
  icon: AppstoreOutlined,
  guard: [],
  children: [
    {
      path: "/master-data/account-management",
      title: "Accounts Management",
      key: "AccountsManagements",
      icon: UserSwitchOutlined,
      guard: [],
    },
    {
      path: "/master-data/manage-sites",
      title: "Manage Sites",
      key: "ManageSites",
      icon: ControlOutlined,
      guard: [],
    },
    {
      path: "/master-data/manage-channels",
      title: "Manage Office",
      key: "ManageOffices",
      icon: ApartmentOutlined,
      guard: [],
    },
  ],
};

export default masterDataMenuConfig;
