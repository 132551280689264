/**
 *
 * This hook will be called after the useCallBack hook is executed
 *
 * u
 *  * @param when : when this conditon is true usePrompt hook will be called back
 */

import { useHistory } from "react-router-dom";
import { useState, useCallback, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";

import { usePrompt } from "./usePromt";
import { setRoutRerenderKey } from "@app/redux/actions/authActions";

export const useCallbackPrompt = (when: any) => {
  const history = useHistory();
  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState(null) as any;
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const dispatch = useDispatch();

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
  }, []);

  const handleBlockedNavigation = useCallback(
    (nextLocation) => {
      if (!confirmedNavigation) {
        setShowPrompt(true);
        setLastLocation(nextLocation);
        dispatch(setRoutRerenderKey(uuidv4()));
        return false;
      }
      return true;
    },
    [confirmedNavigation]
  );

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
      dispatch(setRoutRerenderKey(uuidv4()));
    }
  }, [confirmedNavigation, lastLocation]);

  usePrompt(handleBlockedNavigation, when);

  return [showPrompt, confirmNavigation, cancelNavigation];
};
