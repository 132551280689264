import {
  SafetyOutlined,
  SolutionOutlined,
  TeamOutlined,
} from "@ant-design/icons";

import MenuConfigType from "@app/types/MenuConfigType";

const userManagementMenuConfig: MenuConfigType = {
  path: "/user-management/",
  title: "User Management",
  key: "UserManagement",
  icon: TeamOutlined,
  guard: [],
  children: [
    {
      path: "/user-management/users",
      title: "Users",
      key: "ManageUsers",
      icon: SolutionOutlined,
      guard: [],
    },
    {
      path: "/user-management/user-groups",
      title: "User Groups",
      key: "ManageUserGroups",
      icon: SafetyOutlined,
      guard: [],
    },
  ],
};

export default userManagementMenuConfig;
