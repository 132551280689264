export function splitArrayToColumns(array: any[], columnSize: number) {
  const columnsArray = [];
  const chunkedArray = ([] as any).concat.apply(
    [],
    array.map((elem, i) => {
      return i % columnSize ? [] : [array.slice(i, i + columnSize)];
    })
  );

  for (let i = 0; i < columnSize; i++) {
    columnsArray.push(
      chunkedArray
        .map((x: any[]) => x[i])
        .filter((index: any) => typeof index !== "undefined")
    );
  }

  return columnsArray;
}

export function uniqueArray(array: any[]) {
  const tmpArray = array.map((value) => JSON.stringify(value));
  return Array.from(new Set(tmpArray)).map((value) => JSON.parse(value)); 
}
