import { VerticalState } from "@app/redux/types/VerticalState";
import {
  VerticalActionTypes,
  SET_MODULES,
  MODULES_FETCH_SUCCESSFUL,
} from "../actionTypes/verticalTypes";

export const initialState: VerticalState = {
  id: 0,
  modules: [],
  isModulesFetched: false,
};

export default function (
  state = initialState,
  action: VerticalActionTypes
): VerticalState {
  switch (action.type) {
    case SET_MODULES:
      return {
        ...state,
        id: action.verticalId,
        modules: action.payload,
      };
    case MODULES_FETCH_SUCCESSFUL:
      return {
        ...state,
        isModulesFetched: action.isFetched,
      };
    default:
      return state;
  }
}
