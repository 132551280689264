import React from "react";

import RouteSwitch from "@app/components/common/routes/RouteSwitch";
import RouteConfigType from "@app/types/RouteConfigType";

const RetentionPage = React.lazy(() => import("@pages/Retention"));


const retentionRoutes: RouteConfigType[] = [
  {
    component: RouteSwitch,
    path: "/retention",
    title: "Retention",
    exact: false,
    guard: [],
    useInBreadcrumbs: false,
    routes: [
      {
        component: RetentionPage,
        path: "/retention",
        title: "Manage Retention",
        exact: true,
        guard: [],
        useInBreadcrumbs: true,
      },
    ],
  },
];

export default retentionRoutes;
