import React from "react";

import RouteSwitch from "@app/components/common/routes/RouteSwitch";
import RouteConfigType from "@app/types/RouteConfigType";
import AddEditUser from "@pages/UserManagement/User/AddEditUser";
import AddEditUserGroup from "@pages/UserManagement/UserGroup/AddEditUserGroup";

const ManageUsers = React.lazy(() => import("@pages/UserManagement/User"));

const ManageUserGroups = React.lazy(() =>
  import("@pages/UserManagement/UserGroup")
);

const MANAGE_USERS = "Manage Users";

const UserManagementRoutes: RouteConfigType[] = [
  {
    component: RouteSwitch,
    path: "/user-management",
    title: MANAGE_USERS,
    exact: false,
    guard: [],
    useInBreadcrumbs: false,
    routes: [
      {
        component: RouteSwitch,
        path: "/user-management/users",
        title: MANAGE_USERS,
        exact: false,
        guard: [],
        useInBreadcrumbs: true,
        routes: [
          {
            component: AddEditUser,
            path: "/user-management/users/create",
            title: "Create User",
            exact: true,
            guard: [],
            useInBreadcrumbs: true,
            disableBreadcrum: true,
          },
          {
            component: AddEditUser,
            path: "/user-management/users/edit/:id",
            title: "Update User",
            exact: true,
            guard: [],
            useInBreadcrumbs: true,
          },
          {
            component: ManageUsers,
            path: "/user-management/users",
            title: MANAGE_USERS,
            exact: true,
            guard: [],
            useInBreadcrumbs: false,
          },
        ],
      },
      {
        component: RouteSwitch,
        path: "/user-management/user-groups",
        title: "Manage User Groups",
        exact: false,
        guard: [],
        useInBreadcrumbs: true,
        routes: [
          {
            component: AddEditUserGroup,
            path: "/user-management/user-groups/create",
            title: "Create User Group",
            exact: true,
            guard: [],
            useInBreadcrumbs: true,
            disableBreadcrum: true,
          },
          {
            component: AddEditUserGroup,
            path: "/user-management/user-groups/edit/:id",
            title: "Update User Group",
            exact: true,
            guard: [],
            useInBreadcrumbs: true,
          },
          {
            component: ManageUserGroups,
            path: "/user-management/user-groups",
            title: "Manage User Groups",
            exact: true,
            guard: [],
            useInBreadcrumbs: false,
          },
        ],
      },
    ],
  },
];

export default UserManagementRoutes;
