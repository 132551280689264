import React from "react";
import styles from "./index.module.less";

type MasterLayoutPageTitle = {
  children?: any;
  title?: string;
  tags?: any;
  pageTitleClassName?: any;
};

export default (props: MasterLayoutPageTitle) => {
  return (
    <div className={props.pageTitleClassName ? props.pageTitleClassName : styles.yjPageTitleWrapper}>
      <div className={styles.yjPageTitle}>
        <h2>{props.title} </h2> &nbsp;<p className={styles.yjLegalOnHoldStatus}>{props.tags}</p>
      </div>
      <div className={styles.yjPageButtonGroupWrapper}>
        {props.children}
      </div>
    </div>
  );
};

