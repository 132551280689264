import Constants from "@app/constants";
import { useOktaAuth } from "@okta/okta-react";
import { Button, Col, Row } from "antd";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const Logout: React.FC = () => {
  const history = useHistory();
  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    if (authState?.isAuthenticated) history.push(Constants.postSignedInRedirect);
  }, [authState]);

  return (
    <Row
      justify="center"
      align="middle"
      style={{ minHeight: "calc(100vh - 65px)" }}
    >
      <Col className="yj_error_banner">
        <Row gutter={20} justify="center" align="middle">
          <Col span="24" style={{ textAlign: "center", marginBottom: "20px" }}>
            You have been logged out !
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={() => oktaAuth.signInWithRedirect()}
              className="yj_error_btns"
            >
              Login
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Logout;
