import {
  UPDATE_COLUMNS,
  UPDATE_SINGLE_COLUMN,
  CLEAR_ALL_FILTERS,
  UPDATE_COLUMN_PARAMETERS,
  UPDATE_SEARCH_PARAMETERS,
  UPDATE_FILTERS,
  ADD_GRID_FILTER,
  GET_CURRENT_FILTERS,
  UPDATE_SAVED_FILTER_TEMPLATES,
  APPLY_SAVED_FILTER,
  REMOVE_GRID_FILTER,
  FILTER_CLOUD_UPDATED,
  FILTER_TEMPLATE_SAVED,
  REMOVE_GRID_FILTERS,
  UPDATE_PRE_APPLIED_FILTERS,
  SELECT_SAVED_FILTER,
  HIDE_SAVE_NEW_FILTER,
  REMOVE_SELECT_SAVED_FILTER,
  UPDATE_GRID_HAS_UPDATES,
} from "../actionTypes/gridActionTypes";
import { AppThunk } from "@app/types/AppThunk";
import {
  CreateFilterTemplateRequest,
  SavedFilterTemplate,
} from "@app/types/filterTemplateTypes";
import {
  createFilterTemplate,
  deleteFilterTemplate,
  getFilterTemplates,
  modifyFilterTemplate,
} from "@app/api/genericDataTable";
import { GenericFilter } from "@app/components/GenericDataTable/types";

export const updateGridColumns = (data: any) => {
  return {
    type: UPDATE_COLUMNS,
    payload: data,
  };
};

export const updateGridSingleColumn = (element: any) => {
  return {
    type: UPDATE_SINGLE_COLUMN,
    payload: element,
  };
};

export const updateColumnQueryParameters = (siteId: any): AppThunk => {
  return (dispatch) => {
    dispatch(updateColumnParameters([{ key: "siteid", value: siteId }]));
  };
};

export const updateSearchQueryParameters = (
  searchParameters: any
): AppThunk => {
  return (dispatch) => {
    dispatch(updateSearchParameters(searchParameters));
  };
};

export const updateColumnParameters = (element: any) => {
  return {
    type: UPDATE_COLUMN_PARAMETERS,
    payload: element,
  };
};

export const updateSearchParameters = (element: any) => {
  return {
    type: UPDATE_SEARCH_PARAMETERS,
    payload: element,
  };
};

export const clearGridFilters = () => {
  return {
    type: CLEAR_ALL_FILTERS,
    payload: [],
  };
};

export const hideSaveNewFilterBtn = () => {
  return {
    type: HIDE_SAVE_NEW_FILTER,
  };
};

export const updateGridFilters = (value: boolean) => {
  return {
    type: UPDATE_FILTERS,
    payload: value,
  };
};

export const getCurrentGridFilters = () => {
  return {
    type: GET_CURRENT_FILTERS,
  };
};

export const addGridFilter = (value: GenericFilter) => {
  return {
    type: ADD_GRID_FILTER,
    payload: value,
  };
};

export const removeGridFilter = (filterKey: any, value: any) => {
  return {
    type: REMOVE_GRID_FILTER,
    payload: { key: filterKey, value },
  };
};

export const removeGridFilters = (filterKeys: Array<string>) => {
  return {
    type: REMOVE_GRID_FILTERS,
    payload: filterKeys,
  };
};

export const filterCloudUpdated = (filters: any) => {
  return {
    type: FILTER_CLOUD_UPDATED,
    payload: filters,
  };
};

export const applyASavedFilter = (element: any) => {
  return {
    type: APPLY_SAVED_FILTER,
    payload: element,
  };
};

export const onSelectedSavedFilter = (payload: SavedFilterTemplate) => {
  return {
    type: SELECT_SAVED_FILTER,
    payload: payload,
  };
};

export const removeSelectedSavedFilter = () => {
  return {
    type: REMOVE_SELECT_SAVED_FILTER,
  };
};

export const updateSavedFilterTemplates = (filters: SavedFilterTemplate[]) => {
  return {
    type: UPDATE_SAVED_FILTER_TEMPLATES,
    payload: filters,
  };
};

export const saveFilterTemplate = (
  createFilterRequest: CreateFilterTemplateRequest,
  gridKey: string,
  groupedValue: string
): AppThunk => {
  return async (dispatch) => {
    await createFilterTemplate({
      ...createFilterRequest,
      gridKey: gridKey,
      groupedValue: groupedValue,
    });
    dispatch(getSavedFilters(gridKey, groupedValue));
  };
};

export const getSavedFilters = (
  gridKey: string,
  groupedValue?: string
): AppThunk => {
  return async (dispatch) => {
    if (gridKey) {
      const {
        data,
      }: { data: SavedFilterTemplate[] } = await getFilterTemplates(
        gridKey,
        groupedValue
      );
      dispatch(updateSavedFilterTemplates(data));
      dispatch(updateFilterTemplateSaved(false));
    }
  };
};

export const deleteSavedFilterTemplate = (filterId: string): AppThunk => {
  return async (dispatch) => {
    if (filterId) {
      await deleteFilterTemplate(filterId).catch((error) => {});
    }
  };
};

export const editSavedFilterTemplate = (
  templateId: string,
  filter: CreateFilterTemplateRequest,
  gridKey: string,
  groupedValue?: string
): AppThunk => {
  return async (dispatch) => {
    await modifyFilterTemplate(templateId, filter);
    dispatch(getSavedFilters(gridKey, groupedValue));
  };
};

export const updateFilterTemplateSaved = (filterTemplateSaved: boolean) => {
  return {
    type: FILTER_TEMPLATE_SAVED,
    payload: filterTemplateSaved,
  };
};

export const updatePreAppliedFilters = (preAppliedFilters: GenericFilter[]) => {
  return {
    type: UPDATE_PRE_APPLIED_FILTERS,
    payload: preAppliedFilters,
  };
};

export const updateGridHasUpdates = (value: boolean) => {
  return {
    type: UPDATE_GRID_HAS_UPDATES,
    payload: value,
  };
};
