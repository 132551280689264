import { ValueType } from "@app/types/ValueType";
import { FileRecord } from "@app/components/forms/UploaderSubmit/types";

export const NAME = "FILE_DETAILS";

export const SET_FILE_STATUSES = `${NAME}/SET_FILE_STATUSES`;
export const SET_FILE_YEARS = `${NAME}/SET_FILE_YEARS`;
export const SET_PROJECTS = `${NAME}/SET_PROJECTS`;
export const SET_TAGS = `${NAME}/SET_TAGS`;
export const SET_USERS = `${NAME}/SET_USERS`;
export const SET_IS_OPTIONS_FETCHED = `${NAME}/SET_IS_OPTIONS_FETCHED`;
export const SET_SUCCESSED_FILES = `${NAME}/SET_SUCCESSED_FILES`;
export const SET_PENDING_SAVE = `${NAME}/SET_PENDING_SAVE`;
export const SET_FILE_TYPES = `${NAME}/SET_FILE_TYPES`;

export interface SetFileStatusesAction {
  type: typeof SET_FILE_STATUSES;
  fileStatuses: [ValueType] | [];
}

export interface SetFileYearsAction {
  type: typeof SET_FILE_YEARS;
  years: [number] | [];
}

export interface SetProjectsAction {
  type: typeof SET_PROJECTS;
  projects: [ValueType] | [];
}

export interface SetTagsAction {
  type: typeof SET_TAGS;
  tags: [ValueType] | [];
}

export interface SetUsersAction {
  type: typeof SET_USERS;
  users: [ValueType] | [];
}

export interface SetIsOptionsFetchedAction {
  type: typeof SET_IS_OPTIONS_FETCHED;
  isOptionsFetched: boolean;
}

export interface SetSuccessFilesAction {
  type: typeof SET_SUCCESSED_FILES;
  successedFiles: FileRecord[];
}

export interface SetPendingSaveAction {
  type: typeof SET_PENDING_SAVE;
  pendingSave: boolean;
}

export interface SetFileTypesAction {
  type: typeof SET_FILE_TYPES;
  fileTypes: string[];
}

export type FileDetailsActionTypes = SetFileStatusesAction &
  SetFileYearsAction &
  SetProjectsAction &
  SetTagsAction &
  SetUsersAction &
  SetIsOptionsFetchedAction &
  SetSuccessFilesAction &
  SetPendingSaveAction &
  SetFileTypesAction;
