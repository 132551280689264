import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import SideMenu from ".";
import { RootState } from "@app/redux/reducers/state";
import mapMenuConfig from "./utils/mapMenuConfig";
import MenuConfigType from "@app/types/MenuConfigType";
import useLocalStorage from "../../hooks/useLocalStorage";

type PropTypes = {
  menuConfig: MenuConfigType[];
};

const mainMenuTreeKey = "MenuTree";

const SideMenuContainer: React.FC<PropTypes> = ({ menuConfig }) => {
  const { menuItems, routeKey } = useSelector((state: RootState) => state.auth);

  const [menu, setMenu] = useLocalStorage(mainMenuTreeKey, []);

  useEffect(() => {
    if (menuItems && menuItems.length > 0) {
      setMenu(menuItems);
    }
  }, [menuItems, setMenu]);

  return <SideMenu menu={mapMenuConfig(menuConfig, menu)} key={routeKey} />;
};

export default SideMenuContainer;
