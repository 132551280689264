import MenuConfigType from "@app/types/MenuConfigType";
import { BarChartOutlined } from "@ant-design/icons";

const dashboardConfig: MenuConfigType = {
  path: "/dashboard",
  title: "Dashboard",
  key: "Dashboard",
  icon: BarChartOutlined,
  guard: [],
};

export default dashboardConfig;
