import { SET_FIRM_NAME_AND_LOGO } from "../actionTypes/firmActionTypes";
import { IFirm } from "../types/FirmState";

const initialFirmState: IFirm = {
  firmLogo: "",
  firmName: "",
};

const firmReducer = (state = initialFirmState, action: any) => {
  switch (action.type) {
    case SET_FIRM_NAME_AND_LOGO: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export default firmReducer;
