import React, { Fragment, useEffect, useState } from "react";
import WizardConfigType from "@app/types/WizardConfigType";
import UserGroupWizard from "@app/components/forms/UserManagement/UserGroups/UserGroupWizard";
import { useForm } from "antd/lib/form/Form";
import { Beforeunload } from "react-beforeunload";
import { useParams } from "react-router-dom";
import styles from "./index.module.less";

import PageContent from "@app/components/PageContent";
import PageTitle from "@app/components/PageTitle";
import StepOneBasicUserGroupInformation from "@app/components/forms/UserManagement/UserGroups/UserGroupWizard/StepOneBasicUserGroupInformation";
import StepTwoFileAreaPermisson from "@app/components/forms/UserManagement/UserGroups/UserGroupWizard/StepTwoFileAreaPermisson";
import {
  createUserGroup,
  loadUserGroupDropdownData,
  loadUserGroupForUpdate,
  updateUserGroup,
} from "@app/api/userGroupService";
import mapToUserGroupForm, {
  mapFormUserGroupsData,
  UserGroupType,
  mapFormUserGroupsDataForUpdate,
  mapInitialFormUserGroupData,
} from "@app/components/forms/UserManagement/utils/mapToUserGroupForm";
import {
  errorNotification,
  successNotification,
} from "@app/utils/antNotifications";
import { PromptOnload } from "@app/utils/confirm/onloadPrompt";
import CancelChangesModal from "@app/utils/confirm/CancelChangesModal";
import { UserGroupContext } from "@app/components/forms/UserManagement/UserGroups/userGroupContext/userGroupContext";
import logger from "@app/utils/logger";
import { IsFormDataChanged } from "@app/utils/forms/formDataEquality";

const USER_GROUP_HOME = "/user-management/user-groups";
const INDEX_DROP_DOWN_VALUE = 0;
const INDEX_USERGROUP_DATA = 1;
const INDEX_USERGROUP_PERMISSIONS = 2;
const REDIRECT_TIMEOUT = 200;

export default (props: any) => {
  const { id } = useParams<any>();
  const [userGrouDetails, setUserGroupDetails] = useState<UserGroupType>();
  const [formChanaged, setFormChanged] = useState(false);
  const [formCancel, setFormCancel] = useState(false);
  const [channelListContextList, setChannelListContext] = useState([]) as any;
  const [preSelectedChannelList, setPreSelectedChannelList] = useState(
    []
  ) as any;
  const [selectedUsersContext, setSelectedUsersContext] = useState([]) as any;
  const [preSelectedUsersContext, setPreSelectedUsersContext] = useState(
    []
  ) as any;
  const [removedUsersContext, setRemovedUsersContext] = useState([]) as any;
  const [selectAllChannelsConext, setSelectAllChannelsContext] = useState(
    false
  );
  const [formLoaded, setFormLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      loadUserGroupForUpdate(id).then((values) => {
        const mappedUserGroup = mapToUserGroupForm(
          values[INDEX_DROP_DOWN_VALUE],
          values[INDEX_USERGROUP_DATA]
        );
        if (values[INDEX_USERGROUP_PERMISSIONS]?.data?.length > 0) {
          setChannelList(values[INDEX_USERGROUP_PERMISSIONS].data);
        }
        setUserGroupDetails(mappedUserGroup);
        form.setFieldsValue(mappedUserGroup);
        setFormLoaded(true);
      });
    } else {
      loadUserGroupDropdownData().then((values) => {
        const mappedUserGroup = mapToUserGroupForm(values);
        setUserGroupDetails(mappedUserGroup);
        form.setFieldsValue(mappedUserGroup);
        setFormLoaded(true);
      });
    }
  }, [id]);

  const setChannelList = (selectedChannelList: Array<any>) => {
    setPreSelectedChannelList(selectedChannelList);
    setChannelListContext(
      selectedChannelList.map((channels: any) => {
        return {
          index: channels.permissionId,
          grantAllChannels: false,
          channels: channels.channelIds.map((channelId: string) => {
            return {
              allFeatures: false,
              displayText: "",
              id: channelId,
              name: "",
            };
          }),
        };
      })
    );
  };

  const [form] = useForm();
  const userGroupSteps: WizardConfigType[] = [
    {
      key: 1,
      name: "OVERVIEW",
      content: (
        <StepOneBasicUserGroupInformation
          userGroupFormDetails={userGrouDetails}
          formRef={form}
          formBaicInforChanged={() => setFormChanged(true)}
          action={id ? "edit" : "save"}
          setUsersContext={(selectedUsers: any, preselectedUsers: any) => {
            setSelectedUsersContext(selectedUsers);
            setPreSelectedUsersContext(preselectedUsers);
          }}
          setRemovedUsersContext={(deletedPreSelectedUsers: any) => {
            setRemovedUsersContext(deletedPreSelectedUsers);
          }}
        />
      ),
    },
    {
      key: 2,
      name: "FILE AREA PERMISSIONS",
      content: (
        <StepTwoFileAreaPermisson
          formRef={form}
          action={id ? "edit" : "save"}
          formFileAreaPermissonChanged={() => setFormChanged(true)}
          setChannelContextList={(channelList: any) => {
            setChannelListContext(channelList);
          }}
          setSelectAllChannelsContext={(selectAll) => {
            setSelectAllChannelsContext(selectAll);
          }}
        />
      ),
    },
  ];

  const PageTitleName = () => {
    if (!id) {
      return props.title;
    } else {
      if (userGrouDetails) {
        return `${props.title} - ${userGrouDetails.name}`;
      } else {
        return props.title;
      }
    }
  };

  const onCancel = () => {
    if (form.isFieldsTouched() || formChanaged) {
      setFormCancel(true);
    } else {
      props.history.push(USER_GROUP_HOME);
    }
  };

  const onSave = (processedFormValues: any) => {
    const mergedValues = {
      ...processedFormValues,
      ...form.getFieldsValue(),
    } as UserGroupType;
    //Get total count of channel list for validate
    let channelCount = 0;
    channelListContextList.forEach((permission: any) => {
      channelCount = channelCount + (permission?.channels?.length || 0);
    });
    if (!!channelListContextList.length && !!channelCount) {
      if (id) {
        const initialFormData = mapInitialFormUserGroupData(
          userGrouDetails,
          preSelectedChannelList
        );
        const mappedUserDataForCompare = mapFormUserGroupsDataForUpdate(
          mergedValues,
          channelListContextList,
          preSelectedChannelList,
          true
        );
        const mappedUserData = mapFormUserGroupsDataForUpdate(
          mergedValues,
          channelListContextList,
          preSelectedChannelList,
          false
        );

        if (IsFormDataChanged(initialFormData, mappedUserDataForCompare)) {
          onUpdate(mappedUserData);
        } else {
          //React's useState Won't immediately work so that we have to use setTimeout
          setFormChanged(false);
          successNotification([""], "Nothing to Update");
          setTimeout(() => {
            props.history.push(USER_GROUP_HOME);
          }, REDIRECT_TIMEOUT);
        }
      } else {
        const mappedUserData = mapFormUserGroupsData(
          mergedValues,
          channelListContextList
        );
        onCreate(mappedUserData);
      }
    } else {
      errorNotification([""], "Select at least one permission");
    }
  };

  const onCreate = (mappedUserData: any) => {
    createUserGroup(mappedUserData)
      .then((response) => {
        setFormCancel(true);
        successNotification([""], "User Group created successfully");
        props.history.push(USER_GROUP_HOME);
      })
      .catch((error) => {
        errorNotification([""], "Failed to Create User Group");
        logger.error("User management module", "Create User Group", error);
      });
  };

  const onUpdate = (mappedUserData: any) => {
    updateUserGroup(id, mappedUserData)
      .then((response) => {
        setFormCancel(true);
        successNotification([""], "User Group updated successfully");
        props.history.push(USER_GROUP_HOME);
      })
      .catch((error) => {
        errorNotification([""], "Failed to Update User Group");
        logger.error("User management module", "Update User Group", error);
      });
  };

  return (
    <Fragment>
      <PageTitle
        pageTitleClassName={styles.yjManageUserGroupTitle}
        title={PageTitleName()}
      />
      <PageContent>
        {
          <CancelChangesModal
            visible={formCancel}
            title={id ? "Leave Page" : "Discard User Group"}
            content={
              id
                ? "The changes made to the user group will be discarded.  Are you sure you want to proceed?"
                : "Your details will not be saved. Are you sure you want to discard the user group?"
            }
            okText={id ? "Proceed" : "Discard"}
            cancelText="Cancel"
            onCancel={() => {
              setFormCancel(false);
            }}
            onOk={() => {
              props.history.push(USER_GROUP_HOME);
            }}
          />
        }
        {!formCancel && formChanaged && (
          <PromptOnload isBlocking={true} isSaving={false} />
        )}
        <Beforeunload onBeforeunload={(event) => event.preventDefault()} />
        <UserGroupContext.Provider
          value={{
            channelListContextList,
            selectedUsersContext,
            preSelectedUsersContext,
            selectAllChannelsConext,
            removedUsersContext,
          }}
        >
          <UserGroupWizard
            permissonList={channelListContextList}
            formRef={form}
            history={props.history}
            steps={userGroupSteps}
            onCancel={onCancel}
            formLoaded={formLoaded}
            onSave={(processedFormValues) => onSave(processedFormValues)}
            action={id ? "edit" : "save"}
          />
        </UserGroupContext.Provider>
      </PageContent>
    </Fragment>
  );
};
