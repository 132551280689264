import {
  Button,
  Col,
  Form,
  FormInstance,
  Row,
  Drawer,
  Modal as AntModal,
} from "antd";
import unionBy from "lodash/unionBy";
import React, { useContext, useEffect, useState } from "react";
import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

import styles from "./index.module.less";
import InfinityList from "@app/components/InfinityList";
import useStateRef from "@app/hooks/useStateRef";
import config from "@app/utils/config";
import { formActionType } from "@app/utils";
import { UserGroupContext } from "../userGroupContext/userGroupContext";
import useWindowDimensions from "@app/hooks/useWindowDimensions";
import { OperationalServiceTypes } from "@iris/discovery.fe.client";

const { confirm } = AntModal;

type userGrouPermissionInfo = {
  formRef: FormInstance;
  setChannelContextList?: (channelList: any) => void;
  setSelectAllChannelsContext?: (selectAll: boolean) => void;
  formFileAreaPermissonChanged?: () => void;
  action: formActionType;
};

const VIEW_FILES = 1;
const ALL_FEATURES = 2;
const MIN_SCRREN_RESOLUTION = 1366;
const MIN_INFINITY_HEIGHT = 380;
const MAX_INFINITY_HEIGHT = 695;

export default ({
  formRef,
  setChannelContextList,
  formFileAreaPermissonChanged,
  action,
}: userGrouPermissionInfo) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [channelsList, setChannelList, channelListRef] = useStateRef([]);
  const [selectedList, setSelectedList] = useState<Array<any>>() as any;

  const [uneditedList, setUneditedList] = useState<Array<any>>() as any;
  const [edited, setEdited, editedRef] = useStateRef(false);
  const { channelListContextList } = useContext(UserGroupContext);
  const [, setDeletedList, deleteListRef] = useStateRef([]);
  const [, setSelectedRecordCount, selectedRecordCountRef] = useStateRef(0);

  const [, setSelectedRecords, selectedRecordstRef] = useStateRef(0);

  const { width } = useWindowDimensions();

  const [totalRecordCount, setTotalRecordCount] = useState(0);

  useEffect(() => {
    const channels = channelListRef.current;
    return () => {
      setChannelContextList && setChannelContextList(channels);
    };
  }, [channelsList]);

  useEffect(() => {
    setChannelList(channelListContextList);
    setUneditedList(channelListContextList);
  }, []);

  useEffect(() => {
    setChannelContextList && setChannelContextList(channelsList);
  }, [channelsList]);

  const handleCancel = () => {
    if (edited) {
      confirm({
        title:
          action === "edit"
            ? "The changes made to the feature will be discarded.  Are you sure you want to proceed?"
            : "Are you sure you want to discard the changes?",
        icon: <ExclamationCircleOutlined />,
        okText: action === "edit" ? "Proceed" : "Yes",
        cancelText: action === "edit" ? "Cancel" : "No",
        onOk() {
          setChannelList(uneditedList ? [...uneditedList] : []);
          setShowEditModal(false);
          setEdited(false);
        },
      });
    } else {
      setChannelList(uneditedList ? [...uneditedList] : []);
      setShowEditModal(false);
      setEdited(false);
    }
  };

  const updateChannelList = (index: number, channelDetailsList: any) => {
    const foundIndex = channelListRef.current.findIndex(
      (channel: any) => channel.index === index
    );

    if (foundIndex > -1) {
      const exsisteChannelList = [...channelListRef.current];

      const exsistingChannel = {
        index: index,
        channels: channelDetailsList,
        grantAllChannels: channelDetailsList.length === totalRecordCount,
      };
      exsisteChannelList[foundIndex] = exsistingChannel;
      setChannelList(exsisteChannelList);
      setUneditedList(exsisteChannelList);
    } else {
      const filteredChannelList = channelListRef.current.filter(
        (channel: any) => channel.index !== index
      );

      let permissonGranted = false;

      if (index === VIEW_FILES) {
        const allFeaturePermisson = channelListRef.current.filter(
          (channel: any) => channel.index === ALL_FEATURES
        ).grantAllFeatures;
        if (allFeaturePermisson) {
          permissonGranted = true;
        } else {
          if (channelDetailsList.length === totalRecordCount) {
            permissonGranted = true;
          }
        }
      } else {
        if (channelDetailsList.length === totalRecordCount) {
          permissonGranted = true;
        }
      }

      filteredChannelList.push({
        index: index,
        channels: channelDetailsList,
        grantAllChannels: permissonGranted,
      });
      setChannelList(filteredChannelList);
      setUneditedList(filteredChannelList);
    }
  };

  const handleAllFeaturesSave = () => {
    if (
      channelListRef.current.find(
        (channel: any) => channel.index === ALL_FEATURES
      )
    ) {
      updateChannelList(ALL_FEATURES, selectedList);
      if (
        channelListRef.current.find(
          (channel: any) => channel.index === VIEW_FILES
        )
      ) {
        const viewFilesList = channelListRef.current.find(
          (channel: any) => channel.index === VIEW_FILES
        )?.channels;

        const commonSites = viewFilesList.filter(
          (value: any) => !deleteListRef.current.includes(value.id)
        );

        const mergedPermissons = unionBy(commonSites, selectedList, "id");
        updateChannelList(VIEW_FILES, mergedPermissons);
      }
    } else {
      updateChannelList(ALL_FEATURES, selectedList);
      if (
        channelListRef.current.find(
          (channel: any) => channel.index === VIEW_FILES
        )
      ) {
        const viewFiles = channelListRef.current.find(
          (channel: any) => channel.index === VIEW_FILES
        )?.channels;
        const mergedSites = unionBy(selectedList, viewFiles, "id");
        updateChannelList(VIEW_FILES, mergedSites);
      } else {
        updateChannelList(VIEW_FILES, selectedList);
      }
    }
  };

  const handleViewFilesSave = () => {
    updateChannelList(VIEW_FILES, selectedList);
    if (
      channelListRef.current.find(
        (channel: any) => channel.index === ALL_FEATURES
      )
    ) {
      const allFearuresList = channelListRef.current.find(
        (channel: any) => channel.index === ALL_FEATURES
      )?.channels;
      const commonSites = [] as any;
      allFearuresList.forEach((feature: any) => {
        if (
          selectedList.some(
            (selectedItem: any) => selectedItem.id === feature.id
          )
        ) {
          commonSites.push(feature);
        }
      });
      updateChannelList(ALL_FEATURES, commonSites);
    }
  };

  const handleSave = (index: any) => {
    if (editedRef.current) {
      if (index === ALL_FEATURES) {
        handleAllFeaturesSave();
      } else {
        handleViewFilesSave();
      }
      setShowEditModal(false);
    } else {
      setShowEditModal(false);
    }
    setEdited(false);
  };

  const renderSelectedList = (index: number) => {
    return !!channelListRef.current.length &&
      channelListRef.current.find((channel: any) => channel.index === index)
        ?.channels.length > 0
      ? channelListRef.current
          .find((permission: any) => permission.index === index)
          ?.channels.map((channel: any) => channel.id)
      : undefined;
  };

  const renderCheckedList = (index: number) => {
    return !!channelListRef.current.length &&
      channelListRef.current.find((channel: any) => channel.index === index)
        ?.channels.length > 0
      ? channelListRef.current.find(
          (permission: any) => permission.index === index
        )?.channels
      : undefined;
  };

  const onSelectInfiniteList = (
    value: any,
    selectedListInput: any,
    totalRecordCountValue: any,
    index: any,
    clearVisible = false
  ) => {
    let channels = [];

    if (
      channelListRef.current.find((channel: any) => channel.index === index)
    ) {
      channels = channelListRef.current
        .find((channel: any) => channel.index === index)
        .channels?.filter(
          (value: any) => !deleteListRef.current.includes(value.id)
        );
    }

    const mappedList = selectedListInput.map((item: any) => {
      return {
        ...item,
        allFeatures: index === ALL_FEATURES,
      };
    });

    const mergedSites = unionBy(channels, mappedList, "id");
    setSelectedRecords(mergedSites);
    setSelectedRecordCount(mergedSites.length);
    setTotalRecordCount(totalRecordCountValue);
    setSelectedList(mergedSites);
  };

  const ChannelSelectorModal = (field: any, index: any) => {
    return (
      selectedIndex !== -1 &&
      selectedIndex === index && (
        <Drawer
          title={selectedIndex === VIEW_FILES ? "View Files" : "All Features"}
          destroyOnClose={true}
          maskClosable={false}
          visible={showEditModal}
          className={"yjDrawerPanel"}
          onClose={handleCancel}
          width={700}
          footer={[
            <div
              key={index}
              className={styles.yjUserGroupsPermissionsDrawerFooterWrapper}
            >
              <div className={styles.yjUserGroupViewFilesNotification}>
                <InfoCircleOutlined />
                {index === ALL_FEATURES
                  ? ` The selected channels will be given the permission to view
                files as well. `
                  : ` Revoking access to view files may result in revoking access to certain features as well.`}
              </div>

              <Button
                key={`${index}_back`}
                type="default"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                key={`${index}_submit`}
                type="primary"
                onClick={() => handleSave(index)}
              >
                Save
              </Button>
            </div>,
          ]}
        >
          <div>
            <p>
              {index === ALL_FEATURES
                ? `Select the Office(s) where the user should be able to perform All Features`
                : `Select the Offices(s) where the user should be able to View, Assign, Email and Download Files`}
            </p>
            <InfinityList
              heightInPx={
                width <= MIN_SCRREN_RESOLUTION
                  ? MIN_INFINITY_HEIGHT
                  : MAX_INFINITY_HEIGHT
              }
              onSelect={(
                value,
                selectedListInput,
                totalRecordCountValue,
                clearVisible
              ) => {
                onSelectInfiniteList(
                  value,
                  selectedListInput,
                  totalRecordCountValue,
                  index,
                  clearVisible
                );
              }}
              selectedList={renderSelectedList(index)}
              hasCheckbox={true}
              paginatedLimit={20}
              endpoint={
                config.api[OperationalServiceTypes.MasterDataService].channels
              }
              notFoundContent="No Results Found"
              formatValue={(value: any) => {
                return <div>{value.name}</div>;
              }}
              onChange={(changed) => {
                setEdited(changed);
                formFileAreaPermissonChanged && formFileAreaPermissonChanged();
              }}
              onChangeCheckBox={(event, itemId) => {
                const preSlectedList = renderSelectedList(index) as [];
                let removedList = preSlectedList as any;

                if (event.checked && removedList && !!removedList.length) {
                  setDeletedList(
                    deleteListRef.current.filter((item: any) => item !== itemId)
                  );

                  removedList = removedList.filter(
                    (item: any) => item !== itemId
                  );
                } else {
                  if (
                    preSlectedList &&
                    preSlectedList.find((item: any) => item === itemId)
                  ) {
                    const tempDeletedList = deleteListRef.current;
                    tempDeletedList.push(itemId);
                    setDeletedList(tempDeletedList);
                  }
                }
              }}
              showRecordCounter={true}
              selectedCount={selectedRecordCountRef.current}
              checkedList={selectedRecordstRef.current}
              deletedList={deleteListRef.current}
              idKeyValue={"id"}
              listItemStyle={styles.yjUserGroupsInfinityListComponent}
            />
          </div>
        </Drawer>
      )
    );
  };

  const setChannelCountLabel = (index: any) => {
    const channelList = channelListRef.current.find(
      (channel: any) => channel.index === index
    )?.channels;

    const selectAll = channelListRef.current.find(
      (channel: any) => channel.index === index
    )?.grantAllChannels;

    return channelList && !!channelList.length
      ? selectAll
        ? "All Offices Selected"
        : channelList.length === 1
        ? `1 Office Selected`
        : `${channelList.length} Offices Selected`
      : "No Offices Selected";
  };

  const onClickSelectChannel = (index: any) => {
    setSelectedIndex(formRef?.getFieldValue(["permissions", index, "value"]));
    setSelectedList(
      channelListRef.current.length > 0 &&
        channelListRef.current[
          formRef?.getFieldValue(["permissions", index, "value"])
        ]?.channels.length > 0
        ? channelListRef.current[
            formRef?.getFieldValue(["permissions", index, "value"])
          ].channels
        : undefined
    );
    setEdited(false);
    setShowEditModal(true);
    setDeletedList([]);
    setSelectedRecordCount(
      renderSelectedList(index) ? renderSelectedList(index).length : []
    );
    setSelectedRecords(renderCheckedList(index));
  };

  const renderDynamicPermissonFields = (field: any, index: any) => {
    return (
      <Row key={field.name} gutter={24}>
        <Col span={4}>
          <Form.Item name={[field.name, "name"]} colon={false}>
            {formRef?.getFieldValue(["permissions", index, "name"])}
          </Form.Item>
        </Col>
        <Col span={4}>
          {setChannelCountLabel(
            formRef?.getFieldValue(["permissions", index, "value"])
          )}
        </Col>
        <Col span={4}>
          <Button type="primary" onClick={() => onClickSelectChannel(index)}>
            SELECT CHANNEL
          </Button>
        </Col>
        {ChannelSelectorModal(
          field,
          formRef?.getFieldValue(["permissions", index, "value"])
        )}
      </Row>
    );
  };

  return (
    <>
      <Form form={formRef}>
        <Row gutter={24}>
          <Col span={24}>
            <div>
              <Form.List name="permissions">
                {(fields: any, { add, remove }: any) => {
                  return (
                    <div>
                      {fields.map((field: any, index: any) =>
                        renderDynamicPermissonFields(field, index)
                      )}
                    </div>
                  );
                }}
              </Form.List>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};
