import React, { useEffect, useState } from "react";
import { Breadcrumb } from "antd";
import { withRouter, matchPath } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

import RouteConfigType from "../../types/RouteConfigType";
import styles from "./index.module.less";
import { RootState } from "@app/redux/reducers/state";

const Breadcrumbs = (props: any) => {
  const [breadcrumbs, setBreadcrumbs]: any[] = useState([]);

  const { dynamicBreadcrumValues } = useSelector(
    (state: RootState) => state.configuration
  );

  const buildPath = (path: string): string => {
    const generatedPath = matchPath(props.location.pathname, { path });
    return generatedPath ? generatedPath.url : path;
  };

  const _navigate = (path: string, e: any) => {
    e.preventDefault();
    props.history.push(path);
  };

  const pushToBreadcrumbs = (breadcrumbsArray: any[], route: any) => {
    if (route.useInBreadcrumbs) {
      breadcrumbsArray.push(route);
    }
  };

  useEffect(() => {
    const _breadcrumbArray: any = [];
    const buildBreadcrumbs = (routes: RouteConfigType[] | undefined) => {
      if (!routes) {
        return;
      }
      for (const route of routes) {
        if (
          matchPath(props.location.pathname, {
            path: route.path,
            exact: route.exact,
          })
        ) {
          pushToBreadcrumbs(_breadcrumbArray, route);
          buildBreadcrumbs(route.routes);
        }
      }
    };
    buildBreadcrumbs(props.routes);
    setBreadcrumbs(_breadcrumbArray);
  }, [props]);

  const renderBreadcrumValue = (i: RouteConfigType) => {
    return i.dynamicBreadcrumbIndex || i.dynamicBreadcrumbIndex === 0
      ? dynamicBreadcrumValues[i.dynamicBreadcrumbIndex]
      : i.title;
  };

  const _generateBreadcrumbs = () => {
    let keyCount = 0;
    return breadcrumbs.map((i: any) => {
      keyCount++;
      return i.disableBreadcrum ? (
        <Breadcrumb.Item key={keyCount}>{i.title}</Breadcrumb.Item>
      ) : (
        <Breadcrumb.Item
          href={buildPath(i.path)}
          onClick={(e) => _navigate(buildPath(i.path), e)}
          key={keyCount}
        >
          {renderBreadcrumValue(i)}
        </Breadcrumb.Item>
      );
    });
  };

  return (
    <div className={styles.yjBreadcrumbWrapper}>
      <Breadcrumb className={styles.yjBreadcrumb}>
        <Breadcrumb.Item>
          <HomeOutlined />
        </Breadcrumb.Item>
        {_generateBreadcrumbs()}
      </Breadcrumb>
    </div>
  );
};

export default withRouter(Breadcrumbs);
