import { CloseOutlined, MailOutlined } from "@ant-design/icons";
import {
  Col,
  Row,
  Form,
  Input,
  Select,
  FormInstance,
  Button,
  List,
  Tabs,
  Tooltip,
} from "antd";
import { orderBy } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import { Rule } from "antd/lib/form";
import axios from "axios";

import Modal from "@app/components/common/Modal";
import { required } from "@app/components/forms/validators";
import InfinitySelect, { InfinitySelectGetOptions } from "@app/components/InfinitySelect";
import { ValueType } from "@app/types/ValueType";
import config from "@app/utils/config";
import { UserGroupType } from "../../utils/mapToUserGroupForm";
import styles from "./index.module.less";
import { UserGroupContext } from "../userGroupContext/userGroupContext";
import { checkGroupNameExsistance } from "@app/api/userGroupService";
import {
  prevenPrecedingSpaces,
  prevenPrecedingSpacesOnPaste,
} from "@app/utils/forms";
import { formActionType, getParameterizedUrl } from "@app/utils";
import InfinityList from "@app/components/InfinityList";
import useWindowDimensions from "@app/hooks/useWindowDimensions";
import { OperationalServiceTypes } from "@iris/discovery.fe.client";
import { getInfiniteRecords } from "@app/api/infiniteRecordsService";
import logger from "@app/utils/logger";

const { TabPane } = Tabs;
const { Option } = Select;

type userGrouBasicInfo = {
  formRef: FormInstance;
  userGroupFormDetails?: UserGroupType;
  formBaicInforChanged?: () => void;
  setUsersContext?: (selectedUsers: any, preselectedUsers: any) => void;
  setRemovedUsersContext?: (deletedPreSelectedUsers: any) => void;
  action: formActionType;
};
const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const NEWLY_SELECTED = "1";
const PREVIOUSLY_SELECTED = "2";
const MIN_SCRREN_RESOLUTION = 1366;
const MIN_INFINITY_HEIGHT = 315;
const MAX_INFINITY_HEIGHT = 400;

const renderDropdownOptions = (option: ValueType) => {
  return (
    <Option key={option.value} value={option.value}>
      {option.name}
    </Option>
  );
};

const getPopupContainer = (trigger: any) => {
  return trigger.parentNode as HTMLElement;
};

export default ({
  formRef,
  userGroupFormDetails,
  formBaicInforChanged,
  setUsersContext,
  action,
  setRemovedUsersContext,
}: userGrouBasicInfo) => {
  const { id } = useParams<any>();
  const [users, setUsers] = useState<any>([]);

  const [loadedUsers, setLoadedUsers] = useState<Array<any>>([]);
  const [selectedUserList, setSelectedUserList] = useState<Array<any>>([]);
  const [preSelectedUsers, setPreSelectedUsers] = useState<Array<any>>([]);
  const [deletedPreSelectedUsers, setDeletedPreSelectedUsers] = useState<
    Array<any>
  >([]);
  const [displayUsersViewModal, setDisplayUserViewModal] = useState<boolean>(
    false
  );
  const [removedUserId, setRemovedUserId] = useState<any | undefined>();
  const {
    selectedUsersContext,
    preSelectedUsersContext,
    removedUsersContext,
  } = useContext(UserGroupContext);
  const [selectedTab, setSelectedTab] = useState<any | undefined>();
  const { width } = useWindowDimensions();

  useEffect(() => {
    return () => {
      if (action === "edit") {
        setRemovedUsersContext &&
          setRemovedUsersContext(deletedPreSelectedUsers);
        setUsersContext &&
          setUsersContext(
            selectedUserList,
            selectedUserList.map((user) => user.id)
          );
      } else {
        setUsersContext && setUsersContext(selectedUserList, users);
      }
    };
  }, [selectedUserList, users, deletedPreSelectedUsers]);

  let cancelToken: any;

  useEffect(() => {
    if (action === "edit") {
      const preSelectedUser =
        userGroupFormDetails?.users?.filter(
          (userId) => !removedUsersContext.includes(userId)
        ) || [];
      setSelectedUserList(selectedUsersContext);
      setDeletedPreSelectedUsers(removedUsersContext);
      setUsers(preSelectedUsersContext);
      setLoadedUsers(
        Array.from(new Set([...preSelectedUser, ...preSelectedUsersContext]))
      );
    } else {
      setSelectedUserList(selectedUsersContext);
      if (preSelectedUsersContext && !!preSelectedUsersContext.length) {
        setPreSelectedUsers(selectedUsersContext);
        setLoadedUsers(preSelectedUsersContext);
        setUsers(preSelectedUsersContext);
        formRef?.setFieldsValue({
          users: preSelectedUsersContext,
        });
      }
    }
  }, [action, userGroupFormDetails]);

  useEffect(() => {
    setUsersContext && setUsersContext(selectedUserList, users);
  }, [selectedUserList, users]);

  useEffect(() => {
    if (action === "edit") {
      formRef?.setFieldsValue({
        removedUsers: deletedPreSelectedUsers,
      });
    }
  }, [deletedPreSelectedUsers]);

  const sortUsers = (userList: any) => {
    const lastNameSorter = (user: any) => user.lastName.toLowerCase();
    const firstNameSorter = (user: any) => user.firstName.toLowerCase();
    const emailSorter = (user: any) => user.email.toLowerCase();

    return orderBy(
      userList,
      [lastNameSorter, firstNameSorter, emailSorter],
      ["asc", "asc", "asc"]
    );
  };

  const onChangeUsers = (event: any, userList: Array<any> | undefined) => {
    formBaicInforChanged && formBaicInforChanged();
    setUsers(event);
    if (userList) {
      if (action === "edit") {
        const preSelectedUser: string[] = userGroupFormDetails?.users || [];
        const nonPreSelectedUsersList = userList
          ?.filter((tmpUser: any) => !preSelectedUser.includes(tmpUser.id))
          .map((tmpUser) => tmpUser.id);
        formRef?.setFieldsValue({
          addedUsers: nonPreSelectedUsersList,
        });
        /**
         * Get Remaing Pre Selected User from dropdown and filter the deleted Pre Selected User
         */
        const remainigPreSelectedUser = event.filter(
          (val: any) => !nonPreSelectedUsersList.includes(val)
        );
        const removedPreSelectedUser = preSelectedUser.filter(
          (val: any) => !remainigPreSelectedUser.includes(val)
        );
        setDeletedPreSelectedUsers(removedPreSelectedUser);
        setSelectedUserList(
          sortUsers(
            userList.filter((user) => !preSelectedUser.includes(user.id))
          )
        );
      } else {
        formRef?.setFieldsValue({
          users: userList?.map((contact: any) => {
            return contact.id;
          }),
        });
        setSelectedUserList(sortUsers(userList));
      }
    }
  };

  const onRemoveContact = (userId: any) => {
    const currentUsers = selectedUserList.filter((user) => user.id !== userId);
    setPreSelectedUsers(
      currentUsers.length > 0
        ? currentUsers.filter((user: any) => !user.preSelected)
        : [-1]
    );
    setSelectedUserList(currentUsers);
    setRemovedUserId(userId);
    setUsers(
      !!currentUsers.length
        ? currentUsers?.map((user: any) => {
            return user.id;
          })
        : []
    );
    formRef?.setFieldsValue({
      users: !!currentUsers.length
        ? currentUsers?.map((user: any) => {
            return user.id;
          })
        : [],
    });

    formRef?.setFieldsValue({
      addedUsers: !!currentUsers.length
        ? currentUsers?.map((user: any) => {
            return user.id;
          })
        : [],
    });
  };

  const renderUserList = () => (
    <List
      locale={{ emptyText: "No Users Selected" }}
      itemLayout="horizontal"
      dataSource={selectedUserList}
      renderItem={(item) => (
        <List.Item key={item.id}>
          <div className={styles.yjUserGroupSelectedUsersList}>
            <div className={styles.yjUserGroupSelectedUsersListLeft}>
              <Tooltip title={item.firstName} placement={"topLeft"}>
                <p className={styles.yjUserGroupSelectedUsersListName}>
                  {item.lastName}, {` ${item.firstName}`}
                </p>
              </Tooltip>
              <Tooltip title={item.email} placement={"topLeft"}>
                <p>
                  <MailOutlined />
                  {item.email}
                </p>
              </Tooltip>
            </div>
            <div className={styles.yjUserGroupSelectedUsersListRight}>
              <Button
                onClick={() => onRemoveContact(item.id)}
                icon={<CloseOutlined />}
              />
            </div>
          </div>
        </List.Item>
      )}
    />
  );

  const renderInfinityListItem = (value: any, onRemove: any) => (
    <div className={styles.yjManageUserGroupsInfinityListComponent}>
      <div className={styles.yjManageUserGroupsListItemValue}>
        <Tooltip title={value.firstName} placement={"topLeft"}>
          <p>
            {value.lastName}, {` ${value.firstName}`}
          </p>
        </Tooltip>
        <Tooltip title={value.email} placement={"topLeft"}>
          <p>
            <MailOutlined />
            {value.email}
          </p>
        </Tooltip>
      </div>
      <div className={styles.yjManageUserGroupsListItemAction}>
        <Button onClick={() => onRemove(value.id)} icon={<CloseOutlined />} />
      </div>
    </div>
  );

  const onRemovePreSelectedUser = (userId: any) => {
    setRemovedUserId(userId);
    setDeletedPreSelectedUsers((deletedUsers) => [...deletedUsers, userId]);
  };

  const renderTabs = () => {
    const preSelectedCount = userGroupFormDetails?.users?.length || 0;
    return (
      <Tabs onChange={setSelectedTab} activeKey={selectedTab}>
        <TabPane
          tab={
            <>
              Newly Selected
              <span className={styles.yjBadge}>{selectedUserList.length}</span>
            </>
          }
          key={NEWLY_SELECTED}
        >
          {renderUserList()}
        </TabPane>

        <TabPane
          tab={
            <>
              Previously Selected
              <span className={styles.yjBadge}>
                {preSelectedCount - deletedPreSelectedUsers.length}
              </span>
            </>
          }
          key={PREVIOUSLY_SELECTED}
        >
          <InfinityList
            heightInPx={
              width <= MIN_SCRREN_RESOLUTION
                ? MIN_INFINITY_HEIGHT
                : MAX_INFINITY_HEIGHT
            }
            listClassName={"yjInfinityListClass"}
            paginatedLimit={20}
            endpoint={getParameterizedUrl(
              config.api[OperationalServiceTypes.UserService].usersByGroupId,
              id
            )}
            notFoundContent="No User(s) Found"
            onItemRemove={(user) => onRemovePreSelectedUser(user)}
            removedItem={deletedPreSelectedUsers}
            formatValue={(value: any, onRemove) => {
              return renderInfinityListItem(value, onRemove);
            }}
            idKeyValue="id"
          />
        </TabPane>
      </Tabs>
    );
  };

  const userDisplayModal = () => {
    return (
      <Modal
        destroyOnClose={true}
        size={"small"}
        visible={displayUsersViewModal}
        key="userDisplayModal"
        title="Selected Users"
        closable={displayUsersViewModal}
        onCancel={() => {
          setDisplayUserViewModal(false);
          setSelectedTab(NEWLY_SELECTED);
        }}
        footer={[
          <Button
            key="yes"
            onClick={() => {
              setDisplayUserViewModal(false);
              setSelectedTab(NEWLY_SELECTED);
            }}
            type="default"
          >
            CLOSE
          </Button>,
        ]}
      >
        <div className={styles.yjModalContentWrapper}>
          {action === "edit" ? renderTabs() : renderUserList()}
        </div>
      </Modal>
    );
  };

  const displayUserList = () => {
    const preSelectedCount = userGroupFormDetails?.users?.length || 0;
    const selectedCount =
      action === "edit"
        ? selectedUserList.length +
          (preSelectedCount - deletedPreSelectedUsers.length)
        : selectedUserList?.length;
    return (
      <Button
        className="yjNoWrapperButton"
        onClick={() => setDisplayUserViewModal(true)}
      >
        {selectedCount === 0 ? `None Selected` : `${selectedCount} Selected`}
      </Button>
    );
  };

  const exsistsUserGroup = (userGroupName: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      cancelToken = axios.CancelToken.source();
      if (userGroupFormDetails?.name === userGroupName) {
        resolve(true);
      } else {
        checkGroupNameExsistance(userGroupName, cancelToken)
          .then(() => {
            reject("User Group name already exists");
          })
          .catch(() => {
            resolve(true);
          });
      }
    });
  };

  const userGroupExsistance = (): Rule => ({
    validator(rule, value) {
      return value ? exsistsUserGroup(value) : Promise.resolve();
    },
  });

  const getPaginatedRecords = async  (page: number, method: InfinitySelectGetOptions, searchValue?: string): Promise<Array<any>> => {
    const transformFilters: any = {};
    /**
     * Will add the keyvalue if dropdown still visible
     */
    if (searchValue) {
      transformFilters.search = searchValue;
    }

    const options = {
      limit: 10,
      offset: page - 1,
    }
    return getInfiniteRecords(config.api[OperationalServiceTypes.UserService].getUserList , options)
        .then((res: any) => {
          logger.info('SideSelection','getPaginatedRecords',res.data);
          if (res.data) {
            return res.data;
          } else {
            logger.error('SideSelection','getPaginatedRecords',res.error);
            return []
          }
        })
        .catch((error: any) => {
          logger.error('SideSelection','getPaginatedRecords',error);

          return [];
        });
  };


  const renderInfo = () => (
    <>
      <Col span={8}>
        <Form.Item
          rules={[required, userGroupExsistance()]}
          label={"User Group Name"}
          name="name"
        >
          <Input
            onInput={(event) => prevenPrecedingSpacesOnPaste(event)}
            onKeyDown={(event) => prevenPrecedingSpaces(event)}
            autoComplete="off"
            maxLength={50}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item rules={[required]} label={"Description"} name="description">
          <TextArea
            onKeyDown={(event) => prevenPrecedingSpaces(event)}
            onInput={(event) => prevenPrecedingSpacesOnPaste(event)}
            autoSize={{ minRows: 1, maxRows: 2 }}
            autoComplete="off"
            maxLength={100}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item rules={[required]} label={"Status"} name="status">
          <Select
            disabled={true}
            style={{ width: "100%" }}
            getPopupContainer={getPopupContainer}
          >
            {userGroupFormDetails && userGroupFormDetails.statusOptions
              ? userGroupFormDetails.statusOptions.map((option) =>
                  renderDropdownOptions(option)
                )
              : null}
          </Select>
        </Form.Item>
      </Col>
      <Col span={8}>
        <Row gutter={24}>
          <Col span={18}>
            <Form.Item label="User(s)" name="User(s)">
              <div className="yjInfinateScrollMultiselector">
                <InfinitySelect
                  mode="multiple"
                  getPaginatedRecords={getPaginatedRecords}
                  formatValue={(value) => {
                    return `${value.displayText}`;
                  }}
                  notFoundContent="No Users Available"
                  notLoadContent="Failed to load values in user dropdown"
                  value={users}
                  onChange={onChangeUsers}
                  preSelected={preSelectedUsers}
                  removeById={removedUserId}
                  placeholder="Select Users"
                  maxTagCount={0}
                  maxTagPlaceHolder={() => "Select Users"}
                  returnObject={true}
                  defaultValues={loadedUsers}
                />
              </div>
            </Form.Item>
          </Col>
          <Col span={4}>{displayUserList()}</Col>
        </Row>
      </Col>
      <Form.Item name="users" />
    </>
  );

  const generateCreateMode = () => <Row gutter={16}>{renderInfo()}</Row>;
  const generateEditMode = () => (
    <>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item label={"User Group Id"} name="groupId">
            <Input disabled={true} autoComplete="off" maxLength={50} />
          </Form.Item>
        </Col>
        {renderInfo()}
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item label={"Created By"} name="createdBy">
            <Input disabled={true} autoComplete="off" maxLength={50} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={"Created Date"} name="created">
            <Input disabled={true} autoComplete="off" maxLength={50} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={"Modified Date"} name="modified">
            <Input disabled={true} autoComplete="off" maxLength={50} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name="addedUsers" />
      <Form.Item name="removedUsers" />
    </>
  );

  return (
    <div className={styles.yjAddUserGroupOverviewStepWrapper}>
      {userDisplayModal()}
      <Form
        form={formRef}
        size="middle"
        {...layout}
        name="basic"
        layout="horizontal"
        onChange={formBaicInforChanged}
      >
        {action === "save" && generateCreateMode()}
        {action === "edit" && generateEditMode()}
      </Form>
    </div>
  );
};
