import { Skeleton } from "antd";
import { FormInstance } from "antd/es/form/Form";
import moment from "moment";
import React, { useEffect, useState } from "react";

import { loadUserDropdownData } from "@app/api/userService";
import Users from ".";
import { formActionType } from "@app/utils";

export interface UserDetailsContainer {
  action: formActionType;
  formRef?: FormInstance;
  onFinish?: (values: any) => void;
  onChange?: () => void;
  userId?: string;
  userData?: any;
  formLoaded?: (loaded: boolean) => void;
}

export const mapUserData = (data: any) => {
  return {
    ...data,
    role: data.role?.value,
    status: data.status?.value,
    userGroups: data.groups?.map((group: any) => group.id),
    created: data.created ? moment(data.created) : null,
    modified: data.modified ? moment(data.modified) : null,
  };
};

//Always set Status as Active, can be change later to data.status?.value
export const maptoShowUserData = (data: any) => {
  return {
    ...data,
    role: data.role?.value,
    status: 1,
    deletedPreSelectedUserGroups: [],
    loadedPreSelectedUserGroups: data.groups
      ? data.groups?.map((group: any) => group.value)
      : [],
    groups: [],
    createdBy: data.createdBy ? data.createdBy.name : null,
    created: data.created ? data.created.split("T")[0] : null,
    modified: data.modified ? data.modified.split("T")[0] : null,
  };
};

const PRIMARY_ADMIN = 1;

export default ({
  action,
  formRef,
  onFinish,
  onChange,
  userId,
  userData,
  formLoaded,
}: UserDetailsContainer) => {
  const [userLoaded, setUserLoaded] = useState(false);
  const [userDetails, setUserDetails] = useState<{} | undefined>();

  const populateUserDropdownData = () => {
    loadUserDropdownData().then((values) => {
      const roles = values[0]?.data;
      //Assign Primary Admin to the dropdown Only For Edit and  userDetail's role is Primary Admin
      if (userData && userData?.role.value === PRIMARY_ADMIN) {
        roles.push(userData?.role);
      }
      setUserDetails({
        roles: roles,
        status: values[1]?.data,
      });
      setUserLoaded(true);
      formLoaded && formLoaded(true);
    });
  };

  useEffect(() => {
    if (userData && action === "edit") {
      console.log(maptoShowUserData(userData));
      formRef?.setFieldsValue(maptoShowUserData(userData));
      populateUserDropdownData();
    } else {
      if (action === "save") {
        formRef?.setFieldsValue(mapUserData({ status: 1, role: 3 }));
        populateUserDropdownData();
      }
    }
  }, [userData, formRef]);

  return userLoaded ? (
    <Users
      onFinish={onFinish}
      onChange={onChange}
      formRef={formRef}
      action={action}
      userDetails={userDetails}
      userData={userData}
    />
  ) : (
    <Skeleton active />
  );
};
