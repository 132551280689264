import { Button, Col, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./index.module.less";
import Logo from "../../../styles/assets/images/Logo.png";
import UserImg from "../../../styles/assets/images/userLogo.png";
import { useOktaAuth } from "@okta/okta-react";
import { RootState } from "@app/redux/reducers/state";
import { useDispatch, useSelector } from "react-redux";
import { SET_TENANT } from "@app/redux/actionTypes/authActionTypes";

function TenantSelection() {
  const [tids, setTids] = useState<Array<string>>([]);
  const { authState } = useOktaAuth();
  const tenant = useSelector((rootState: RootState) => rootState.auth.tenant);
  const [selected, setSelected] = useState(tenant);
  const dispatch = useDispatch();

  useEffect(() => {
    setTids((authState?.accessToken?.claims.tid as Array<string>) ?? []);
  }, [authState]);

  const onChange = (val: string) => setSelected(val);

  const onSelect = () => {
    dispatch({ type: SET_TENANT, payload: selected });
  };

  return (
    <Row justify="center" className={styles.yj_tenant_selection_container}>
      <Col>
        <div className={styles.yj_tenant_selection}>
          <img src={Logo} style={{ paddingBottom: "10px" }} />
          <div style={{ paddingBottom: "10px" }}>
            <Row justify="space-between" align="middle">
              <Col span={9}>
                <hr />
              </Col>
              <Col>
                <img className={styles.yj_tenant_userLogo} src={UserImg} />
              </Col>
              <Col span={9}>
                <hr />
              </Col>
            </Row>
          </div>
          <hr />
          <div className={styles.yj_tenant_title}>Select the tenant</div>
          <div className={styles.yj_tenant_dropdown}>
            <Select
              placeholder={"Select a tenant"}
              defaultValue={tenant ?? ""}
              onChange={onChange}
              style={{
                width: "370px",
                textAlign: "left",
                marginBottom: "20px",
              }}
            >
              {tids.map((e, index) => (
                <Select.Option key={index} value={e}>
                  {e}
                </Select.Option>
              ))}
            </Select>
          </div>
          <hr />
          <Button
            style={{
              marginTop: "10px",
              width: "370px",
              marginBottom: "20px",
              height: "45px",
            }}
            type="primary"
            disabled={!selected}
            onClick={onSelect}
          >
            Done
          </Button>
        </div>
      </Col>
    </Row>
  );
}

export default TenantSelection;
