import types from "../actionTypes/LicenseActionTypes";
import { LicenseManagementState } from "@app/redux/types/LicenseManagementState";

export const initialState: LicenseManagementState = {
  liceseDetails: undefined,
  options: { compliances: [], statuses: [], verticals: [], storages: [] },
  isDataFetched: false,
  isOptionsFetched: false,
  error: undefined,
  saveSuccessed: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case types.SET_LICENSE_DETAILS:
      return {
        ...state,
        liceseDetails: action.payload,
      };
    case types.DATA_FETCH_SUCESSFUL:
      return {
        ...state,
        isDataFetched: action.payload,
      };
    case types.OPTIONS_FETCH_SUCCESSFUL:
      return {
        ...state,
        isOptionsFetched: action.payload,
      };
    case types.SET_COMPLIANCES:
      return {
        ...state,
        options: {
          ...state.options,
          compliances: action.payload,
        },
      };
    case types.SET_STATUSES:
      return {
        ...state,
        options: {
          ...state.options,
          statuses: action.payload,
        },
      };
    case types.SET_VERTICALS:
      return {
        ...state,
        options: {
          ...state.options,
          verticals: action.payload,
        },
      };
    case types.SET_STORAGES:
      return {
        ...state,
        options: {
          ...state.options,
          storages: action.payload,
        },
      };
    case types.SET_USER_COUNTS:
      return {
        ...state,
        options: {
          ...state.options,
          userCounts: action.payload,
        },
      };
    case types.SET_SUPPORT_LEVELS:
      return {
        ...state,
        options: {
          ...state.options,
          supportLevels: action.payload,
        },
      };
    case types.SET_INTEGRATIONS:
      return {
        ...state,
        options: {
          ...state.options,
          integrations: action.payload,
        },
      };
    case types.HAS_ERRORED:
      return {
        ...state,
        error: action.payload,
      };
    case types.SAVE_SUCCESSED:
      return {
        ...state,
        saveSuccessed: action.payload,
      };
    default:
      return state;
  }
}
