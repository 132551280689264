import Logout from "@app/components/Logout";
import CommonLayout from "@app/layouts/CommonLayout";
import React from "react";

const LogoutPage = () => {
  return (
    <CommonLayout>
      <Logout />
    </CommonLayout>
  );
};

export default LogoutPage;
