import { getParameterizedUrl } from "@app/utils";
import config, { getApiUrl } from "@app/utils/config";
import http, { httpVerbs } from "@app/utils/http";
import HTTPResponse from "@app/utils/http/interfaces/HttpResponse";
import { OperationalServiceTypes } from "@iris/discovery.fe.client";

const FILE_AREA_MODULE = 3;

export const getUserGroupStatus = () => {
  return Promise.resolve({
    status: 200,
    data: [
      { name: "Active", value: 1 },
      { name: "Cancelled", value: 2 },
      { name: "Pending", value: 3 },
    ],
  });
};

export const getPermissons = (moduleId: number) => {
  return http({
    method: httpVerbs.GET,
    url: config.api[OperationalServiceTypes.UserService].getModulePermission,
    params: { moduleId: moduleId },
  });
};

export const loadUserGroupDropdownData = () => {
  return Promise.all([getUserGroupStatus(), getPermissons(FILE_AREA_MODULE)]);
};

export const createUserGroup = (userGroupFormDetails: any) => {
  return http({
    method: httpVerbs.POST,
    url: getApiUrl(
      config.api[OperationalServiceTypes.UserService].createUserGroup
    ),
    data: userGroupFormDetails,
  });
};

export const checkGroupNameExsistance = (
  userGroupName: any,
  cancelToken: any
) => {
  return http({
    method: httpVerbs.HEAD,
    url: getApiUrl(
      config.api[OperationalServiceTypes.UserService].createUserGroup
    ),
    params: { name: userGroupName },
    cancelToken: cancelToken.token,
  });
};

export const getUserGroupDetails = (
  userGroupId: string
): Promise<HTTPResponse<any>> => {
  return http({
    method: httpVerbs.GET,
    url: getParameterizedUrl(
      config.api[OperationalServiceTypes.UserService].userGroupById,
      userGroupId
    ),
  });
};

export const getModulePermissionGroupById = (
  userGroupId: string,
  moduleId: number
): Promise<HTTPResponse<any>> => {
  return http({
    method: httpVerbs.GET,
    url: getParameterizedUrl(
      config.api[OperationalServiceTypes.UserService].modulePermissionGroupById,
      userGroupId
    ),
    params: { moduleId: moduleId },
  });
};

export const loadUserGroupForUpdate = (userGroupId: string) => {
  return Promise.all([
    loadUserGroupDropdownData(),
    getUserGroupDetails(userGroupId),
    getModulePermissionGroupById(userGroupId, FILE_AREA_MODULE),
  ]);
};

export const updateUserGroup = (
  userGroupId: string,
  userGroupFormDetails: any
) => {
  return http({
    method: httpVerbs.PATCH,
    url: getParameterizedUrl(
      config.api[OperationalServiceTypes.UserService].userGroupById,
      userGroupId
    ),
    data: userGroupFormDetails,
  });
};
