const SPACE_BAR = "Spacebar";
export const prevenPrecedingSpaces = (event: any) => {
  if ((event.key === " " || event.key === SPACE_BAR) && !event.target?.value) {
    event.preventDefault();
  }
};

export const prevenPrecedingSpacesOnPaste = (event: any) => {
  const trmiStartedValue = event.target.value.trimStart().split(/ +/).join(" ");
  event.target.value = trmiStartedValue;
};
