import { ValueType } from "@app/types/ValueType";

export default (dropdownValues: any, usergroupDetails?: any): UserGroupType => {
  if (usergroupDetails) {
    return {
      statusOptions: dropdownValues[0]?.data,
      status: usergroupDetails.data.status.value,
      permissions: dropdownValues[1]?.data?.map((x: any) => {
        return {
          checked: false,
          name: x.name,
          value: x.value,
        };
      }),
      description: usergroupDetails.data.description || null,
      name: usergroupDetails.data.name || null,
      createdBy: usergroupDetails.data.createdBy
        ? usergroupDetails.data.createdBy.name
        : null,
      modifiedBy: usergroupDetails.data.modifiedBy
        ? usergroupDetails.data.modifiedBy.name
        : null,
      created: usergroupDetails.data.created
        ? usergroupDetails.data.created.split("T")[0]
        : null,
      modified: usergroupDetails.data.modified
        ? usergroupDetails.data.modified.split("T")[0]
        : null,
      users: usergroupDetails.data.users,
      userCount: usergroupDetails.data.userCount,
      groupId: usergroupDetails.data.groupId,
    };
  } else {
    return {
      statusOptions: dropdownValues[0]?.data,
      status: 1,
      permissions: dropdownValues[1]?.data?.map((x: any) => {
        return {
          checked: false,
          name: x.name,
          value: x.value,
        };
      }),
    };
  }
};

export const mapFormUserGroupsData = (
  groupsInfo: any,
  channelListContextList: any
): UserGroupType => {
  return {
    ...groupsInfo,
    permissions: !!channelListContextList.length
      ? channelListContextList.map((permission: any) => {
          return {
            permissionId: permission.index,
            grantAllChannels: permission.grantAllChannels,
            channels: !!permission.channels.length
              ? !permission.grantAllChannels
                ? permission.channels.map((channel: any) => {
                    return channel.id;
                  })
                : []
              : [],
          };
        })
      : [],
  };
};

export const mapInitialFormUserGroupData = (
  groupsInfo: any,
  preSelectedChannelList: Array<any>
) => {
  return {
    name: groupsInfo.name,
    description: groupsInfo.description,
    addedUsers: [],
    removedUsers: [],
    permissions: !!preSelectedChannelList.length
      ? preSelectedChannelList.map((preSelectedChannel: any) => {
          return {
            permissionId: preSelectedChannel.permissionId,
            grantedChannels: [],
            revokedChannels: [],
          };
        })
      : [],
  };
};

const genaratedPreSelectedChannelIds = (
  preSelectedChannelList: Array<any>,
  index: number
) => {
  const preSelectedChannel = preSelectedChannelList.find(
    (preSelectedChannel: any) => preSelectedChannel.permissionId == index
  );
  return !!preSelectedChannel ? preSelectedChannel.channelIds : [];
};

const filterGrantedChannels = (
  channels: Array<any>,
  index: number,
  preSelectedChannelList: Array<any>
) => {
  const preSelectedChannelIds: Array<string> = genaratedPreSelectedChannelIds(
    preSelectedChannelList,
    index
  );
  return channels
    .map((channel: any) => channel.id)
    .filter((channelId: string) => {
      return !preSelectedChannelIds.includes(channelId);
    });
};

const filterRevokedChannels = (
  channels: Array<any>,
  index: number,
  preSelectedChannelList: Array<any>
) => {
  const preSelectedChannelIds: Array<string> = genaratedPreSelectedChannelIds(
    preSelectedChannelList,
    index
  );
  const channelIds: Array<string> = channels.map((channels) => channels.id);
  return !!channelIds.length
    ? preSelectedChannelIds.filter(
        (preSelectedChannelId) => !channelIds.includes(preSelectedChannelId)
      )
    : preSelectedChannelIds;
};

export const mapFormUserGroupsDataForUpdate = (
  groupsInfo: any,
  channelListContextList: any,
  preSelectedChannelList: Array<any>,
  isCompare: boolean
): UserGroupType => {
  return {
    name: groupsInfo.name,
    description: groupsInfo.description,
    addedUsers: groupsInfo.addedUsers ? groupsInfo.addedUsers : [],
    removedUsers: groupsInfo.removedUsers ? groupsInfo.removedUsers : [],
    permissions: !!channelListContextList.length
      ? channelListContextList.map((permission: any) => {
          const permissionObj: any = {
            permissionId: permission.index,
            grantedChannels: filterGrantedChannels(
              permission.channels,
              permission.index,
              preSelectedChannelList
            ),
            revokedChannels: filterRevokedChannels(
              permission.channels,
              permission.index,
              preSelectedChannelList
            ),
          };
          if (!isCompare) {
            permissionObj.grantAllChannels = permission.grantAllChannels;
          }
          return permissionObj;
        })
      : [],
  };
};

export type UserGroupType = {
  statusOptions?: ValueType[];
  name?: string;
  description?: string;
  status?: number;
  users?: [];
  permissions: PermissonType[];
  createdBy?: string;
  modifiedBy?: string;
  created?: string;
  modified?: string;
  userCount?: string;
  groupId?: string;
  addedUsers?: Array<string>;
  removedUsers?: Array<string>;
};

export type PermissonType = {
  permissionId: number;
  grantAllChannels: boolean;
  channels: [];
};
