import React, { Fragment, useState, useEffect } from "react";
import { Button } from "antd";
import { useForm } from "antd/lib/form/Form";
import { Beforeunload } from "react-beforeunload";
import { useParams } from "react-router-dom";

import styles from "./index.module.less";
import UserDetailsContainer from "@app/components/forms/UserManagement/Users/UserDetailsContainer";
import PageTitle from "@app/components/PageTitle";
import { PageContent } from "@app/layouts/MasterLayout";
import { PromptOnload } from "@app/utils/confirm/onloadPrompt";
import CancelChangesModal from "@app/utils/confirm/CancelChangesModal";
import { createUser, updateUser, getUserDetails } from "@app/api/userService";
import {
  errorNotification,
  successNotification,
} from "@app/utils/antNotifications";
import logger from "@app/utils/logger";
import { IsFormDataChanged } from "@app/utils/forms/formDataEquality";

const MANAGE_USERS_LANDING_PAGE = "/user-management/users";
const USER_COUNT_EXCEEDED_ERROR_CODE = 30002;

const mapUpdatedFormData = (formValues: any) => {
  const remainigPreSelectedUserGroup = formValues.loadedPreSelectedUserGroups.filter(
    (userGroupId: string) =>
      !formValues.deletedPreSelectedUserGroups.includes(userGroupId)
  );
  return {
    firstName: formValues.firstName,
    lastName: formValues.lastName,
    role: formValues.role,
    statusId: formValues.status,
    groups: [...formValues.groups, ...remainigPreSelectedUserGroup],
  };
};

const mapInitialFormData = (data: any) => {
  return {
    firstName: data.firstName,
    lastName: data.lastName,
    role: data.role.value,
    statusId: data.status.value,
    groups: data.groups.map((group: any) => group.value),
  };
};

export default (props: any) => {
  const { id } = useParams<any>();
  const [formChanaged, setFormChanged] = useState(false);
  const [formCancel, setFormCancel] = useState(false);
  const [form] = useForm();
  const [formLoaded, setFormLoaded] = useState(false);

  const [userDetail, setUserDetail] = useState<any | undefined>();

  const PageTitleName = () => {
    if (!id) {
      return props.title;
    } else {
      if (userDetail) {
        return `${props.title} - ${userDetail.firstName} ${userDetail.lastName}`;
      } else {
        return props.title;
      }
    }
  };

  useEffect(() => {
    if (id) {
      getUserDetails(id)
        .then((response: any) => {
          setUserDetail(response.data);
        })
        .catch((error) => {
          errorNotification([""], "Failed to get User");
          logger.error("User management module", "Get User", error);
        });
    }
  }, [id]);

  const onFinish = () => {
    form.validateFields().then((values) => {
      if (id) {
        const initialFormData = mapInitialFormData(userDetail);
        const updatedFormData = mapUpdatedFormData(values);
        if (IsFormDataChanged(initialFormData, updatedFormData)) {
          onUpdateUser(updatedFormData);
        } else {
          setFormCancel(true);
          props.history.push(MANAGE_USERS_LANDING_PAGE);
          successNotification([""], "Nothing to Update");
        }
      } else {
        onCreateUser(values);
      }
    });
  };

  const onUpdateUser = (values: any) => {
    updateUser(id, values)
      .then(() => {
        setFormCancel(true);
        props.history.push(MANAGE_USERS_LANDING_PAGE);
        successNotification([""], "User updated successfully");
      })
      .catch((error) => {
        errorNotification([""], "Failed to update user");
        logger.error("User management module", "Update User", error);
      });
  };

  const onCreateUser = (values: any) => {
    createUser(values)
      .then(() => {
        setFormCancel(true);
        props.history.push(MANAGE_USERS_LANDING_PAGE);
        successNotification([""], "User created successfully");
      })
      .catch((error) => {
        if (
          error.errorCode &&
          error.errorCode === USER_COUNT_EXCEEDED_ERROR_CODE
        ) {
          errorNotification(
            [""],
            "The user limit has been reached.Upgrade your licence or delete any inactive user accounts"
          );
        } else {
          errorNotification([""], "Failed to create user");
          logger.error("User management module", "Add User", error);
        }
      });
  };

  const onCancel = () => {
    if (form.isFieldsTouched() || formChanaged) {
      setFormCancel(true);
    } else {
      props.history.push(MANAGE_USERS_LANDING_PAGE);
    }
  };

  return (
    <Fragment>
      <PageTitle
        title={PageTitleName()}
        pageTitleClassName={styles.yjUserManagementPageTitle}
      ></PageTitle>
      <PageContent>
        {
          <CancelChangesModal
            visible={formCancel}
            title={id ? "Discard Changes" : "Discard User"}
            content={
              id
                ? "The changes made to the user details will be discarded.  Are you sure you want to proceed?"
                : "Your details will not be saved. Are you sure you want to discard the user?"
            }
            okText={id ? "Proceed" : "Discard"}
            cancelText="Cancel"
            onCancel={() => {
              setFormCancel(false);
            }}
            onOk={() => {
              props.history.push(MANAGE_USERS_LANDING_PAGE);
            }}
          />
        }
        {!formCancel && formChanaged && (
          <PromptOnload isBlocking={true} isSaving={false} />
        )}
        <Beforeunload onBeforeunload={(event) => event.preventDefault()} />
        <UserDetailsContainer
          onChange={() => setFormChanged(true)}
          onFinish={onFinish}
          formRef={form}
          action={id ? "edit" : "save"}
          userData={userDetail ? userDetail : undefined}
          formLoaded={(loaded) => {
            setFormLoaded(true);
          }}
        />
        {formLoaded && (
          <div className={styles.yjAddUserManagementButtonWrapper}>
            <div>
              <Button onClick={onCancel} type="default">
                Cancel
              </Button>
              <Button onClick={onFinish} type="primary">
                {id ? "Update" : "Create"}
              </Button>
            </div>
          </div>
        )}
      </PageContent>
    </Fragment>
  );
};
