import { useOktaAuth } from "@okta/okta-react";
import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { WarningOutlined } from "@ant-design/icons";

const AppError: React.FC<{
  message: string;
  showContactAdmin: boolean;
  autoLogoutTime?: number;
  showLogoutButton?: boolean;
}> = ({ message, showContactAdmin, autoLogoutTime, showLogoutButton = true }) => {
  const { oktaAuth } = useOktaAuth();
  const [timer, setTimer] = useState(autoLogoutTime ?? 0);

  const logout = () => oktaAuth.signOut();
  const changeTimer = () => {
    setTimer((timer) => (timer > 0 ? timer - 1 : 0));
  };
  let interval: NodeJS.Timeout;

  useEffect(() => {
    if (autoLogoutTime) {
      setTimeout(logout, autoLogoutTime * 1000);
      interval = setInterval(changeTimer, 1000);
    }
    return () => clearInterval(interval);
  }, []);

  return (
    <Row
      justify="center"
      align="middle"
      style={{ minHeight: "calc(100vh - 65px)" }}
    >
      <Col className="yj_error_banner" span="16">
        <Row gutter={20} style={{ color: "rgb(27, 105, 185)", textAlign: "center", border: "2px dashed rgb(27, 105, 185)", margin: "0 auto", padding: "10% 0", borderRadius: "10px" }}>
          <Col span="24" >
            <WarningOutlined style={{ fontSize: 32, margin: 8, color: "rgb(27, 105, 185)" }} />
            <h1 style={{ color: "red" }}>Error!</h1>
            An error occurred while processing your request.
          </Col>
          {showContactAdmin && (
            <Col
              span="24"
              style={{ textAlign: "center", marginBottom: "10px" }}
            >
              Please contact your system administrator.
            </Col>
          )}
          {autoLogoutTime && (
            <Col
              span="24"
              style={{ textAlign: "center", marginBottom: "10px" }}
            >
              You will be logged out in {timer} seconds.
            </Col>
          )}
          {showLogoutButton && (
            <Button
              type="primary"
              onClick={logout}
              className="yj_error_btns"
              style={{ marginTop: "10px" }}
            >
              Logout
            </Button>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default AppError;
