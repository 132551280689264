import 'rc-tree/assets/index.css';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip';
import Tree from 'rc-tree';
import { Button, Checkbox, Col, Input, InputNumber, Modal, Row, Typography } from 'antd';
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, FolderFilled, MinusSquareFilled, PlusOutlined, PlusSquareFilled } from '@ant-design/icons';
import { DataNode, EventDataNode } from 'antd/lib/tree';
import { MdDeleteSweep } from 'react-icons/md';
import _ from 'lodash';

import './foldertree.css';
import styles from './index.module.less';
import useStateRef from '@app/hooks/useStateRef';
import { errorNotification } from '@app/utils/antNotifications';
import { prevenPrecedingSpaces, prevenPrecedingSpacesOnPaste } from '@app/utils/forms';
import { useSelector } from "react-redux";
import { RootState } from "@app/redux/reducers/state";

const NEW_FOLDER_LABEL = 'New Folder';
const TOOLTIP_ADD_NEW_FOLDER = 'Create Sub Folder';
const TOOLTIP_RENAME_FOLDER = 'Rename Folder';
const TOOLTIP_DELETE_FOLDER = 'Delete Folder';
const LABEL_ADD_PARENT_LABEL = 'ADD FOLDER';
const TOOLTIP_DELETE_CHILD_FOLDERS = 'Delete All Child Folders';
const FOLDER_COLOR_BLUE = '#134A82';
const FOLDER_COLOR_YELLOW = '#24303B';
const BORDER_COLOR_RED = '#C8102E';
export const MIN_RETENTION_VALUE = 1;
export const MAX_RETENTION_VALUE = 20;

export interface TreeDataNode extends DataNode {
  parent?: boolean;
  children?: TreeDataNode[];
  retention?: number;
  folderName?: string;
  checked?: boolean;
  preSaved?: boolean;
  indeterminate?: boolean;
}

interface TreeEventDataNode extends EventDataNode {
  parent: boolean;
}

export interface FolderTreeProps {
  data?: any;
  onChange?: () => void;
  onFolderTreeUnmount?: (treeData: TreeDataNode[]) => void;
  onDupicateFolderAdded?: (duplicateName: boolean) => void;
  onEmptyFolderAdded?: (duplicateFolder: boolean) => void;
}

type renderMode = 'parent' | 'child' | 'addParent' | 'addChild';

export const orderByAlphaNumericTitle = (data: any, key = 'folderName', order = 'asc') => {
  if (data) {
    const returnData = data.slice().sort((firstValue: any, secondValue: any) =>
      firstValue[key].localeCompare(secondValue[key], undefined, {
        numeric: true,
      })
    );
    return order === 'asc' ? returnData : returnData.reverse();
  } else {
    return [];
  }
};

export default ({ data, onChange, onFolderTreeUnmount, onDupicateFolderAdded, onEmptyFolderAdded }: FolderTreeProps) => {
  let cmContainer: any;
  let tooltip: any;
  const [showRetentionModel, setShowRetentionModel] = useState(false);
  const [treeData, setTreeData] = useState<Array<TreeDataNode>>([
    {
      key: '0',
      folderName: NEW_FOLDER_LABEL,
      selectable: false,
      children: [
        {
          key: '0_0',
          folderName: NEW_FOLDER_LABEL,
          retention: 0,
          selectable: false,
          icon: <FolderFilled style={{ color: FOLDER_COLOR_BLUE }} />,
          isLeaf: true,
        },
        {
          key: '0_1',
          folderName: `${NEW_FOLDER_LABEL} (2)`,
          retention: 0,
          selectable: false,
          icon: <FolderFilled style={{ color: FOLDER_COLOR_BLUE }} />,
          isLeaf: true,
        },
      ],
      parent: true,
    },
  ]);
  const [disPlayContextMeny, setDisplayContextMenu] = useState(false);
  const [retention, setRetention] = useState<number>(0);
  const [positon, setPositon] = useState({ x: 0, y: 0 });
  const [hoveredNode, setHoveredNode] = useState<TreeEventDataNode>();
  const [hasPreSaved, setHasPreSaved] = useState<boolean>(false);
  const [checkedKeys, setCheckedKeys] = useState<any[]>([]);
  const [hasPreSavedForChecked, setHasPreSavedForChecked] = useState<boolean>(false);
  const [hideDeleteAllChildFolders, setHideDeleteChildFolders] = useState<boolean>(false);
  const { userPermission } = useSelector( (state: RootState) => state.userManagement );

  const uncheckAll = (data: TreeDataNode[]) => {
    data.forEach((node: any) => {
      node.checked = false;
      if (node.children) {
        uncheckAll(node.children);
      }
    });
    setTreeData([...data]);
  };

  const onCheck = () => {
    onChange && onChange();
    let preSavedChecked = false;
    const getPresaved = (node: TreeDataNode[]) => {
      node.forEach((node: TreeDataNode) => {
        if (node.checked && node.preSaved) {
          preSavedChecked = true;
          return;
        }
        if (node.children) {
          getPresaved(node.children);
        }
      });
    };
    getPresaved(treeData);
    setHasPreSavedForChecked(preSavedChecked);
  };
  const onExpand = (event: any, info: any) => {
    onChange && onChange();
    setAutoExpandedParent(false);
    setExpandedKeys([...event]);
  };
  const inputRef = useRef() as any;
  const buttonRef = useRef() as any;
  const [expandedKeys, setExpandedKeys] = useState<Array<any>>(['0', '0_0', '0_1']);
  const [autoExpandedParent, setAutoExpandedParent] = useState(true);
  const [currentFolderKey, setCurrentFolderKey, folderKeyRef] = useStateRef('');
  const [displayNameError, setDisplayNameError, displayErrorRef] = useStateRef(false);
  const [displayEmptyError, setDisplayEmptyError, displayEmptyErrorRef] = useStateRef(false);

  const getContainer = () => {
    cmContainer = document.createElement('div');
    cmContainer.setAttribute('id', 'containerId');
    document.body.appendChild(cmContainer);
    return cmContainer;
  };

  useEffect(() => {
    return () => {
      onFolderTreeUnmount && onFolderTreeUnmount(treeData);
    };
  }, [currentFolderKey, displayNameError, displayEmptyError]);

  const renderSubFolders = (subFolders: Array<any>, parentFolderIndex: number) => {
    return subFolders.map((subNode: any, index: number) => {
      return {
        id:subNode.id,
        key: `${parentFolderIndex}_${subNode.id}`,
        folderName: subNode.name,
        retention: Number(subNode.retention),
        selectable: false,
        icon: <FolderFilled style={{ color: FOLDER_COLOR_BLUE }} />,
        isLeaf: true,
        preSaved: subNode.presist ?? true,
      };
    });
  };

  const handleDataToTree = (tree: any) => {
    return tree.map((node: any, index: number) => {
      return {
        id: node.id,
        key: `${node.id}`,
        folderName: node.name,
        selectable: false,
        icon: <FolderFilled style={{ color: FOLDER_COLOR_YELLOW }} />,
        children: renderSubFolders(node.subFolders, node.id),
        parent: true,
        preSaved: node.presist ?? true,
      };
    });
  };

  useEffect(() => {
    if (data) {
      setTreeData([...handleDataToTree(data)]);
      setExpandedKeys([...data.map((node: any) => `${node.id}`)]);
      setAutoExpandedParent(true);
    }
  }, [data]);

  const getIcon = (e: TreeDataNode, color: string) => {
    const onChangeChecked = (ev: any) => {
      e.checked = ev.target.checked;
      if (e.children) {
        e.children.forEach((child: TreeDataNode) => {
          child.checked = ev.target.checked;
        });
      }
      checkParentCheckable();
      setTreeData([...treeData]);
      onCheck();
    };
    return (
      <Row justify="start">
        <Col>
          <Checkbox checked={e.checked} indeterminate={e.checked ? undefined : e.indeterminate} onChange={onChangeChecked} />
        </Col>
        <Col>
          <FolderFilled style={{ color: color }} />
        </Col>
      </Row>
    );
  };

  const checkParentCheckable = () => {
    treeData.forEach((node: TreeDataNode) => {
      if (!node.children?.length) return;
      let allChecked = true;
      let indeterminated = false;
      node.children?.forEach((child: TreeDataNode) => {
        if (!child.checked) allChecked = false;
        else indeterminated = true;
      });
      if (allChecked) node.checked = true;
      else node.checked = false;
      if (indeterminated) node.indeterminate = true;
      else node.indeterminate = false;
    });
  };

  const displayEditTreeNodeError = () => {
    if (retention === 0) {
      return;
    }
    if (!retention)
      return (
        <Col className="yj-tree-node-error-col" span={24}>
          <div className="yj-tree-node-error">Required Field</div>
        </Col>
      );
    if (retention < MIN_RETENTION_VALUE)
      return (
        <Col className="yj-tree-node-error-col" span={24}>
          <div className="yj-tree-node-error">Value cannot be less than {MIN_RETENTION_VALUE}</div>
        </Col>
      );
    if (retention > MAX_RETENTION_VALUE)
      return (
        <Col className="yj-tree-node-error-col" span={24}>
          <div className="yj-tree-node-error">Value cannot be more than {MAX_RETENTION_VALUE}</div>
        </Col>
      );
  };

  const displayTreeNodeError = (data: TreeDataNode) => {
    if (data.retention === 0) {
      return;
    }
    if (!data.retention)
      return (
        <Col className="yj-tree-node-error-col" offset={16} span={8}>
          <div className="yj-tree-node-error">Required Field</div>
        </Col>
      );
    if (data.retention < MIN_RETENTION_VALUE)
      return (
        <Col className="yj-tree-node-error-col" offset={16} span={8}>
          <div className="yj-tree-node-error">Value cannot be less than {MIN_RETENTION_VALUE}</div>
        </Col>
      );
    if (data.retention > MAX_RETENTION_VALUE)
      return (
        <Col className="yj-tree-node-error-col" offset={16} span={8}>
          <div className="yj-tree-node-error">Value cannot be more than {MAX_RETENTION_VALUE}</div>
        </Col>
      );
  };

  useEffect(() => {
    onFolderTreeUnmount && onFolderTreeUnmount(treeData);
    checkParentCheckable();
    const getCheckedKeys = (data: TreeDataNode[]) => {
      const checkedKeys: any[] = [];
      data.forEach((node: any) => {
        if (node.checked) {
          checkedKeys.push(node.key);
        }
        if (node.children?.length) {
          checkedKeys.push(...getCheckedKeys(node.children));
        }
      });
      return checkedKeys;
    };

    setCheckedKeys(getCheckedKeys(treeData));

    treeData.forEach((e) => {
      e.icon = getIcon(e, FOLDER_COLOR_YELLOW);
      e.title = <div className="YJ-TreeNode-FolderName">{e.folderName}</div>;
      e.children?.forEach((e) => {
        e.icon = getIcon(e, FOLDER_COLOR_BLUE);
        e.title = (
          <Row justify="start">
            <Col span={24} className="YJ-TreeNode-FolderName">
              {e.folderName}
            </Col>
            <Col span={4}>
              <Checkbox
                onChange={(ev) => {
                  e.retention = ev.target.checked ? 0 : 1;
                  setTreeData([...treeData]);
                }}
                checked={e.retention == 0}
              >
                Permanent
              </Checkbox>
            </Col>
            <Col span={4}>
              <div>
                <InputNumber
                  value={e.retention == 0 ? "-" : e.retention}
                  defaultValue={e.retention == 0 ? "-" : 1}
                  size="small"
                  disabled={e.retention == 0}
                  onChange={(ev) => {
                    (typeof ev == "number" || ev == null) && (e.retention = ev);
                    setTreeData([...treeData]);
                  }}
                />
                <span className="lbl-years">Year(s)</span>
              </div>
            </Col>
            {displayTreeNodeError(e)}
          </Row>
        );
        e.className = 'YJ-folder-tree-node';
      });
    });
  }, [treeData]);

  const updateExpandedKeys = (key: string) => {
    const exsistingKeys = expandedKeys;
    exsistingKeys.push(key);
    setExpandedKeys([...exsistingKeys]);
    setAutoExpandedParent(true);
  };

  const handleFolderAction = (renderMode: renderMode, cancelled: boolean, edited: boolean, discarded = false, onBlur = false) => {
    uncheckAll(treeData);
    if (displayEmptyErrorRef?.current) {
      errorNotification([''], 'Folder name cannot be empty');
      onEmptyFolderAdded && onEmptyFolderAdded(true);
      return;
    }
    onEmptyFolderAdded && onEmptyFolderAdded(false);
    if (onChange) {
      onChange();
    }
    renderMode === 'child'
      ? EditLeafFolder(inputRef.current?.state.value, cancelled, edited)
      : renderMode === 'parent'
      ? EditParentFolder(inputRef.current?.state.value, cancelled, edited, discarded)
      : renderMode === 'addParent'
      ? AddNewParentFolder(true, inputRef.current?.state.value)
      : AddNewSubFolder(true, inputRef.current?.state.value);
  };

  const getFoldername = (folderList: TreeDataNode[], count: number) => {
    const expression = '^New Folder \\([0-9]\\d*\\)$';
    const matched = folderList.filter((value: any) => value.folderName?.toString().trim().match(expression) || value.folderName?.toString().trim() === NEW_FOLDER_LABEL);
    const sortedMatchedFolders = orderByAlphaNumericTitle(matched, 'folderName', 'desc').filter((node: TreeDataNode) => node.folderName !== NEW_FOLDER_LABEL);
    const highestValue = sortedMatchedFolders.length > 0 ? Number(sortedMatchedFolders[0]?.folderName?.toString().split('(')[1].split(')')[0]) : 1;
    return folderList.length > 0 ? `${NEW_FOLDER_LABEL} (${highestValue + 1})` : NEW_FOLDER_LABEL;
  };

  const checkFolderName = (folderList: TreeDataNode[], title: string) => {
    if (
      folderList.filter(
        (folder) =>
          folder.folderName
            ?.toString()
            .replace(/ +(?= )/g, '')
            .toLowerCase()
            .trim() ===
          title
            .replace(/ +(?= )/g, '')
            .trim()
            .toLowerCase()
      ).length > 0
    ) {
      setDisplayNameError(true);
      return true;
    } else {
      setDisplayNameError(false);
      return false;
    }
  };

  const displayDuplicateNameError = () => {
    errorNotification([''], 'Folder Name already exists in the same level');
    if (inputRef.current) {
      inputRef.current.input.style.borderColor = BORDER_COLOR_RED;
    }
    uncheckAll(treeData);
  };

  const generateFolderNode = (key: string | number, icon: { fontSize: number; color: string; default: boolean }, title: any, isLeaf = false, parent = false): TreeDataNode => {
    return {
      key: key,
      folderName: title,
      selectable: false,
      icon: icon.default ? undefined : <FolderFilled style={{ fontSize: icon.fontSize, color: icon.color }} />,
      isLeaf: isLeaf,
      parent: parent,
      retention: 0,
    };
  };

  const saveParentFolder = (folderList: TreeDataNode[], key: string | number, icon: { fontSize: number; color: string; default: boolean }, title: any) => {
    if (checkFolderName(folderList, title)) {
      displayDuplicateNameError();
      onDupicateFolderAdded && onDupicateFolderAdded(true);
    } else {
      onDupicateFolderAdded && onDupicateFolderAdded(false);
      folderList.push(generateFolderNode(key, icon, title, true, true));
      setTreeData([...orderByAlphaNumericTitle(folderList)]);
      setCurrentFolderKey(undefined);
    }
  };

  const saveEditableParentFolder = (folderList: TreeDataNode[], key: string | number, icon: { fontSize: number; color: string; default: boolean }, title: any) => {
    if (!displayErrorRef.current) {
      setCurrentFolderKey(key);
      const orderedFolders = orderByAlphaNumericTitle(folderList);
      orderedFolders.unshift(generateFolderNode(key, icon, title, true, true));
      setTreeData([...orderedFolders]);
    }
  };

  const AddNewParentFolder = (saved = false, title?: any) => {
    onChange && onChange();
    const folderTree = treeData;
    const keys = orderByAlphaNumericTitle(folderTree, 'key', 'desc');
    const previousKey = keys.length > 0 ? keys[0].key : 0;
    const folderCounter = folderTree.filter((node: TreeDataNode) => {
      return node.folderName?.toString().trim().includes(NEW_FOLDER_LABEL);
    });
    const newFolders = [...folderTree];
    const key = previousKey === 0 ? '1' : (Number(previousKey) + 1).toString();
    updateExpandedKeys(key);
    if (saved) {
      const filtredNewFolders = folderTree.filter((folder) => folder.key !== folderKeyRef.current);
      saveParentFolder(filtredNewFolders, folderKeyRef.current ? folderKeyRef.current : '', { color: FOLDER_COLOR_YELLOW, fontSize: 15, default: false }, title);
    } else {
      saveEditableParentFolder(
        newFolders,
        previousKey === 0 ? '1' : (Number(previousKey) + 1).toString(),
        { color: FOLDER_COLOR_YELLOW, fontSize: 15, default: false },
        renderEditableTitle(folderCounter.length <= 0 ? NEW_FOLDER_LABEL : getFoldername(treeData, treeData.length + 1), 'addParent')
      );
    }
  };

  const saveSubFolder = (
    folderList: TreeDataNode[],
    filteredNodes: TreeDataNode[],
    updatedChildren: TreeDataNode[],
    key: string | number,
    icon: { fontSize: number; color: string; default: boolean },
    title: any,
    hoveredObjectInput: TreeDataNode | undefined
  ) => {
    if (checkFolderName(folderList, title)) {
      displayDuplicateNameError();
      onDupicateFolderAdded && onDupicateFolderAdded(true);
    } else {
      onDupicateFolderAdded && onDupicateFolderAdded(false);
      folderList.push(generateFolderNode(key, icon, title, true, false));
      const ordredOjects = orderByAlphaNumericTitle(folderList);
      const newRootObject = {
        ...hoveredObjectInput,
        icon: undefined,
        children: updatedChildren ? [...ordredOjects] : ([] as TreeDataNode[]),
        isLeaf: false,
      } as TreeDataNode;
      filteredNodes.push(newRootObject);
      setCurrentFolderKey(undefined);
      setTreeData([...orderByAlphaNumericTitle(filteredNodes)]);
    }
  };

  const saveEditableSubFOlder = (
    updatedChildren: TreeDataNode[],
    key: string | number,
    icon: { fontSize: number; color: string; default: boolean },
    title: any,
    hoveredObjectInput: TreeDataNode | undefined
  ) => {
    if (!displayErrorRef.current) {
      setCurrentFolderKey(key);
      updatedChildren?.unshift(generateFolderNode(key, icon, title, true, false));
      const newRootObject = {
        ...hoveredObjectInput,
        children: updatedChildren ? [...updatedChildren] : ([] as TreeDataNode[]),
      } as TreeDataNode;
      const folderList = treeData;
      const newRootIndex = folderList.findIndex((folder) => folder.key === hoveredNode?.key);
      folderList[newRootIndex] = newRootObject;
      setTreeData([...folderList]);
    }
  };

  const AddNewSubFolder = (saved = false, title?: any) => {
    onChange && onChange();
    setDisplayContextMenu(false);
    const hoveredObject = treeData.find((folder) => folder.key === hoveredNode?.key);
    const filteredObjects = treeData.filter((folder) => folder.key !== hoveredNode?.key);
    const newChildren = hoveredObject?.children ? (hoveredObject.children as TreeDataNode[]) : ([] as TreeDataNode[]);
    const keys = orderByAlphaNumericTitle(newChildren, 'key', 'desc');
    const folderKey = keys && keys.length > 0 ? `${hoveredNode?.key}_${Number(keys[0].key.toString().split('_')[1]) + 1}` : `${hoveredNode?.key}_0`;
    updateExpandedKeys(folderKey);
    if (saved) {
      const filteredNewChildren = newChildren.filter((folder) => folder.key !== folderKeyRef.current);
      saveSubFolder(filteredNewChildren, filteredObjects, newChildren, folderKeyRef.current, { color: FOLDER_COLOR_BLUE, fontSize: 15, default: false }, title, hoveredObject);
    } else {
      saveEditableSubFOlder(
        newChildren,
        folderKey,
        { color: FOLDER_COLOR_BLUE, fontSize: 15, default: false },
        renderEditableTitle(keys && keys.length > 0 ? getFoldername(newChildren, newChildren.length) : NEW_FOLDER_LABEL, 'addChild'),
        hoveredObject
      );
    }
  };

  const EditParentFolder = (title?: any, cancelled = false, editNode = false, discarded = false) => {
    onChange && onChange();
    const parentKey = discarded ? folderKeyRef.current : hoveredNode?.key;
    const parentObject = treeData.find((node) => node.key === parentKey) as TreeDataNode;
    const parentIndex = treeData.findIndex((node) => node.key === parentKey);
    const filteredParentObjects = treeData.filter((node) => node.key !== parentKey);
    let updatedParentObject;

    if (cancelled) {
      updatedParentObject = {
        ...parentObject,
        folderName: inputRef.current.props.defaultValue,
      } as TreeDataNode;
    } else {
      updatedParentObject = {
        ...parentObject,
        folderName: title ? title : renderEditableTitle(parentObject?.folderName?.toString(), 'parent'),
      } as TreeDataNode;
    }

    if (editNode) {
      treeData[parentIndex] = updatedParentObject;
      setTreeData([...treeData]);
    } else {
      if (!cancelled && checkFolderName(filteredParentObjects, title)) {
        displayDuplicateNameError();
        onDupicateFolderAdded && onDupicateFolderAdded(true);
      } else {
        onDupicateFolderAdded && onDupicateFolderAdded(false);
        filteredParentObjects.push(updatedParentObject);
        setTreeData([...orderByAlphaNumericTitle(filteredParentObjects)]);
      }
    }
  };

  const EditLeafFolder = (title?: any, cancelled = false, editNode = false) => {
    onChange && onChange();
    const parentKey = hoveredNode?.key.toString().split('_')[0];
    const parentObject = treeData.find((node) => node.key === parentKey) as TreeDataNode;
    const parentIndex = treeData.findIndex((node) => node.key === parentKey);
    const filteredParentObjects = treeData.filter((node) => node.key !== parentKey);
    const childObject = parentObject.children?.find((node) => node.key === hoveredNode?.key);
    const childObjectIndex = parentObject.children?.findIndex((node) => node.key === hoveredNode?.key) as number;
    const filtredChildObjects = parentObject.children?.filter((node) => node.key !== hoveredNode?.key) as TreeDataNode[];

    let updatedChildObject;
    if (cancelled) {
      updatedChildObject = {
        ...childObject,
        folderName: inputRef.current.props.defaultValue,
      } as TreeDataNode;
    } else {
      updatedChildObject = {
        ...childObject,
        folderName: title ? title : renderEditableTitle(childObject?.folderName?.toString(), 'child'),
      } as TreeDataNode;
    }

    if (editNode && parentObject.children) {
      parentObject.children[childObjectIndex] = updatedChildObject;
      treeData[parentIndex] = parentObject;
      setTreeData([...treeData]);
    } else {
      if (!cancelled && filtredChildObjects && checkFolderName(filtredChildObjects, title)) {
        displayDuplicateNameError();
        onDupicateFolderAdded && onDupicateFolderAdded(true);
      } else {
        onDupicateFolderAdded && onDupicateFolderAdded(false);
        filtredChildObjects?.push(updatedChildObject);
        parentObject.children = orderByAlphaNumericTitle(filtredChildObjects);
        filteredParentObjects.push(parentObject);
        setTreeData([...orderByAlphaNumericTitle(filteredParentObjects)]);
      }
    }
  };

  const EditFolder = () => {
    setDisplayContextMenu(false);
    if (hoveredNode?.parent) {
      EditParentFolder(undefined, false, true, false);
    } else {
      EditLeafFolder(undefined, false, true);
    }
  };

  const handleOnFoucsInput = (event: any) => {
    event.target.select();
  };

  const handleOnChangeInput = (event: any) => {
    if (event.target.value.length <= 0) {
      setDisplayEmptyError(true);
      inputRef.current.input.style.borderColor = BORDER_COLOR_RED;
      buttonRef.current.disabled = true;
      errorNotification([''], 'Folder name cannot be empty');
    } else {
      buttonRef.current.disabled = false;
      inputRef.current.input.style.borderColor = '';
      setDisplayEmptyError(false);
    }
  };

  const removeNodesFromTree = (tree: TreeDataNode[], keys: any[]) => {
    let deleteIndex: number[] = [];
    tree.forEach((node) => {
      if (keys.includes(node.key)) {
        deleteIndex.push(tree.indexOf(node));
      } else {
        if (node.children) removeNodesFromTree(node.children, keys);
      }
    });
    deleteIndex.forEach((index) => {
      tree.splice(index, 1);
    });
    return tree;
  };

  const removeNodes = (keys: any[]) => {
    onChange && onChange();
    const newTree = removeNodesFromTree(treeData, keys);
    setDisplayContextMenu(false);
    setDisplayNameError(false);
    uncheckAll(treeData);
    setTreeData([...orderByAlphaNumericTitle(newTree)]);
  };

  const cancelOnMouseDown = (event: any, renderMode: renderMode) => {
    event.stopPropagation();
    event.preventDefault();
    setDisplayNameError(false);
    setDisplayEmptyError(false);
    renderMode === 'child' ? EditLeafFolder(undefined, true) : renderMode === 'parent' ? EditParentFolder(undefined, true) : removeNodes([folderKeyRef.current?.toString()]);
  };

  const saveOnMouseDown = (event: any, renderMode: renderMode) => {
    event.stopPropagation();
    event.preventDefault();
    handleFolderAction(renderMode, false, false, false);
  };

  const renderEditableTitle = (defaultValue: any, renderMode: renderMode) => {
    return (
      <>
        <div
          onMouseOver={() => {
            setDisplayContextMenu(false);
          }}
          className={styles.yjFolderStructureEditAction}
        >
          <Input
            onInput={(event) => prevenPrecedingSpacesOnPaste(event)}
            onKeyDown={(event) => prevenPrecedingSpaces(event)}
            onMouseOver={() => {
              setDisplayContextMenu(false);
            }}
            maxLength={50}
            onBlur={(e) => {
              handleFolderAction(renderMode, false, false, false, false);
            }}
            autoFocus
            onFocus={handleOnFoucsInput}
            id="folderNameInput"
            onChange={handleOnChangeInput}
            ref={inputRef}
            onClick={(e) => {
              e.stopPropagation();
            }}
            defaultValue={defaultValue}
            style={{ maxWidth: '250px' }}
          />
          <span
            onMouseOver={() => {
              setDisplayContextMenu(false);
            }}
          >
            <Button
              onMouseOver={() => {
                setDisplayContextMenu(false);
              }}
              ref={buttonRef}
              type="default"
              id="saveFolderName"
              onMouseDown={(event) => saveOnMouseDown(event, renderMode)}
            >
              <CheckOutlined />
            </Button>
          </span>
          <Button type="default" onMouseDown={(event) => cancelOnMouseDown(event, renderMode)}>
            <CloseOutlined />
          </Button>
        </div>
      </>
    );
  };

  const displayTooltip = () => {
    const containerx = document.getElementById('containerId');
    containerx?.remove();
    const container = getContainer();
    Object.assign(cmContainer.style, {
      position: 'absolute',
      left: `${positon.x}px`,
      top: `${positon.y}px`,
      zIndex: '2000',
    });
    ReactDOM.render(tooltip, container);
  };

  const hideTooltip = () => {
    const container = document.getElementById('containerId');
    container?.remove();
    if (cmContainer) {
      ReactDOM.unmountComponentAtNode(cmContainer);
    }
    if (typeof tooltip !== 'undefined' && tooltip) {
      ReactDOM.unmountComponentAtNode(cmContainer);
      tooltip = null;
    }
  };

  const renderOptionsPanel = () => {
    if (disPlayContextMeny && !displayErrorRef.current && !displayEmptyErrorRef.current) {
      tooltip = (
        <div
          hidden={!disPlayContextMeny}
          onMouseLeave={() => {
            setDisplayContextMenu(false);
          }}
          onMouseEnter={() => setDisplayContextMenu(true)}
        >
          <div className="rc-tree-contextmenu">
            <div
              className="yjFolderStructureOverlayActions"
              onMouseEnter={(e: any) => {
                setDisplayContextMenu(true);
              }}
              onMouseLeave={() => setDisplayContextMenu(false)}
            >
              <p hidden={!userPermission.privDMSCanManageTemplates}
                data-tip
                data-for="renameFolder"
                onClick={() => {
                  EditFolder();
                }}
              >
                <EditOutlined disabled={displayEmptyErrorRef.current} />
              </p>

              <ReactTooltip backgroundColor={FOLDER_COLOR_BLUE} id="renameFolder">
                <span>{TOOLTIP_RENAME_FOLDER}</span>
              </ReactTooltip>
              <p
                hidden={hasPreSaved}
                data-tip
                data-for="deleteFolder"
                data-testid="deleteFolder"
                onClick={() => {
                  removeNodes([hoveredNode?.key]);
                }}
              >
                <DeleteOutlined disabled={displayEmptyErrorRef.current} />
              </p>
              <ReactTooltip backgroundColor={FOLDER_COLOR_BLUE} id="deleteFolder">
                <span>{TOOLTIP_DELETE_FOLDER}</span>
              </ReactTooltip>

              <p
                data-tip
                data-for="createSubFOlder"
                hidden={!hoveredNode?.parent || !userPermission.privDMSCanManageTemplates}
                onClick={() => {
                  AddNewSubFolder(false);
                }}
              >
                <PlusOutlined disabled={displayEmptyErrorRef.current} />
              </p>
              <ReactTooltip backgroundColor={FOLDER_COLOR_BLUE} id="createSubFOlder">
                <span>{TOOLTIP_ADD_NEW_FOLDER}</span>
              </ReactTooltip>

              <p
                hidden={hideDeleteAllChildFolders}
                data-tip
                data-for="deleteChildFolders"
                onClick={() => {
                  removeNodes(
                    hoveredNode?.children
                      ? hoveredNode.children
                          .filter((node: any) => !node.preSaved)
                          .map((child) => {
                            return child.key;
                          })
                      : []
                  );
                }}
              >
                <Button className={styles.YjDeleteChildFolderIcon} disabled={displayEmptyErrorRef.current}>
                  <MdDeleteSweep />
                </Button>
              </p>
              <ReactTooltip backgroundColor={FOLDER_COLOR_BLUE} id="deleteChildFolders">
                <span>{TOOLTIP_DELETE_CHILD_FOLDERS}</span>
              </ReactTooltip>
            </div>
          </div>
        </div>
      );
      displayTooltip();
    } else {
      hideTooltip();
    }
    return <></>;
  };

  const onMouseEnterTree = (info: any) => {
    const element = document.elementFromPoint(info.event.clientX, info.event.clientY);
    if (element && !element.classList.contains('YJ-TreeNode-FolderName')) return;

    const hideDeleteAllChildren = info.node && !!info.node.children?.length ? (info.node.children.find((child: any) => !child.preSaved) ? false : true) : true;

    setHideDeleteChildFolders(hideDeleteAllChildren);
    setHasPreSaved(info.node?.preSaved ? true : false);
    setDisplayContextMenu(false);
    setPositon({ x: info.event.clientX, y: info.event.clientY });
    setDisplayContextMenu(true);
    setHoveredNode(info.node);
  };

  const onClickDelete = (event: any) => {
    event.stopPropagation();
    removeNodes(checkedKeys);
    uncheckAll(treeData);
  };

  const onEditDone = () => {
    treeData.forEach((node: TreeDataNode) => {
      node.children?.forEach((child: TreeDataNode) => {
        if (checkedKeys.includes(child.key)) child.retention = retention;
        console.log('onEditDone child.retention',child.retention)

      });
      console.log('onEditDone  node.children', node.children)

    });
    console.log('onEditDone treeData',treeData)
    setTreeData([...treeData]);
    setShowRetentionModel(false);
    setRetention(0);
    uncheckAll(treeData);
  };

  useEffect(() => {
    const replaceNodeWithReactComponent = (element: Element, reactComponent: any) => {
      ReactDOM.render(reactComponent, element);
    };

    let switcher = document.getElementsByClassName('rc-tree-switcher_open');
    if (switcher) {
      for (let i = 0; i < switcher.length; i++) {
        replaceNodeWithReactComponent(switcher[i], <MinusSquareFilled className="YJ-FolderTreeNode-Expand" />);
      }
    }

    switcher = document.getElementsByClassName('rc-tree-switcher_close');
    if (switcher) {
      for (let i = 0; i < switcher.length; i++) {
        replaceNodeWithReactComponent(switcher[i], <PlusSquareFilled className="YJ-FolderTreeNode-Expand" />);
      }
    }
  });

  useEffect(() => {
    return () => hideTooltip();
  }, []);

  return (
    <div className={styles.yjFolderStructureSection}>
      <Modal
        destroyOnClose={true}
        key="100"
        visible={showRetentionModel}
        title={'RETENTION SETTINGS'}
        onCancel={() => setShowRetentionModel(false)}
        footer={[
          <Button key="back" type="default" onClick={() => setShowRetentionModel(false)}>
            cancel
          </Button>,

          <Button key="checkin" type="primary" onClick={onEditDone}>
            done
          </Button>,
        ]}
      >
        <div className={styles.yjModalContentWrapper}>
          <Typography.Title level={5} className="YJ-Retention-Edit-Title">
            Retention
          </Typography.Title>
          <Row justify="start">
            <Col>
              <Checkbox
                onChange={(ev) => {
                  setRetention(ev.target.checked ? 0 : 1);
                }}
                checked={retention == 0}
              >
                Permanent
              </Checkbox>
            </Col>
            <Col>
              <div>
                <InputNumber
                  value={retention == 0 ? '-' : retention}
                  defaultValue={retention == 0 ? '-' : 1}
                  size="small"
                  disabled={retention == 0}
                  onChange={(ev) => {
                    (typeof ev == 'number' || ev == null) && setRetention(ev);
                  }}
                />
                &nbsp;Year(s)
              </div>
            </Col>
            {displayEditTreeNodeError()}
          </Row>
        </div>
      </Modal>
      <div className={styles.yjAddParentFolderButtonWrapper}>
        {renderOptionsPanel()}
        <Button
          disabled={displayEmptyErrorRef.current || !userPermission.privDMSCanManageTemplates}
          hidden={checkedKeys.length <= 0}
          type="default"
          icon={<EditOutlined />}
          onClick={() => {
            setRetention(0);
            setShowRetentionModel(true);
          }}
        >
          Retention
        </Button>
        <Button
          disabled={displayEmptyErrorRef.current || !userPermission.privDMSCanManageTemplates}
          hidden={checkedKeys.length <= 0 || (hasPreSavedForChecked && checkedKeys.length > 0)}
          type="default"
          icon={<DeleteOutlined />}
          onClick={onClickDelete}
        >
          Delete
        </Button>
        <Button
          disabled={displayEmptyErrorRef.current || !userPermission.privDMSCanManageTemplates}
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            AddNewParentFolder(false);
          }}
        >
          {LABEL_ADD_PARENT_LABEL}
        </Button>
      </div>
      <div className="yjFolderStructureTree ant-col ant-col-12">
        <Tree
          onClick={() => {
            onChange && onChange();
          }}
          onMouseEnter={onMouseEnterTree}
          onMouseLeave={() => {
            setDisplayContextMenu(false);
          }}
          onExpand={onExpand}
          autoExpandParent={autoExpandedParent}
          expandedKeys={expandedKeys}
          treeData={treeData}
          virtual={true}
        />
      </div>
    </div>
  );
};
