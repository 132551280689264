import {
  LOGIN_SUCCESS,
  SET_BASE_URLS,
  SET_DYNAMIC_ROUTE_KEY,
  SET_TENANT,
  USER_LOADED,
} from "../actionTypes/authActionTypes";
import { IAuthState } from "../../redux/types/authTypes/auth";
import Constants from "@app/constants";

export const initialState: IAuthState = {
  access_token: "",
  isAuthenticated: null,
  isLoading: false,
  user: null,
  errors: [],
  menuItems: [],
  routeKey: "routeKey",
  userLoaded: false,
  endPoints: null,
  tenant: localStorage.getItem(Constants.tenatKey),
};

export const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false,
        errors: [],
      };
    case SET_DYNAMIC_ROUTE_KEY:
      return {
        ...state,
        routeKey: action.payload,
      };
    case USER_LOADED:
      return {
        ...state,
        userLoaded: action.userLoaded,
        user: action.user,
      };
    case SET_BASE_URLS:
      return {
        ...state,
        endPoints: action.payload,
      };
    case SET_TENANT:
      localStorage.setItem(Constants.tenatKey, action.payload);
      return { ...state, tenant: action.payload, endPoints: null };

    default:
      return state;
  }
};
