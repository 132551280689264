import React from "react";

import RouteSwitch from "@app/components/common/routes/RouteSwitch";
import RouteConfigType from "@app/types/RouteConfigType";

const AutoFilingPage = React.lazy(() => import("@pages/AutoFiling"));
const AutoFilingLogsPage = React.lazy(() => import("@pages/AutoFiling/Logs"));


const DOCUMENT_MANAGE = "Document Maintenance 2";


const documentMaintenanceRoutes: RouteConfigType[] = [
  {
    component: RouteSwitch,
    path: "/document-maintenance",
    title: DOCUMENT_MANAGE,
    exact: false,
    guard: [],
    useInBreadcrumbs: false,
    routes: [
      {
        component: RouteSwitch,
        path: "/document-maintenance/auto-filing-setting",
        title: 'AutoFiling Settings',
        exact: false,
        guard: [],
        useInBreadcrumbs: true,
        routes:[
          {
            component: AutoFilingPage,
            path: "/document-maintenance/auto-filing-setting",
            title: 'AutoFiling Settings',
            exact: true,
            guard: [],
            useInBreadcrumbs: false,
          },
          {
            component: AutoFilingLogsPage,
            path: "/document-maintenance/auto-filing-setting/logs",
            title: 'Logos',
            exact: true,
            guard: [],
            useInBreadcrumbs: false,
          },
        ]
      },
    ],
  },
];
export default documentMaintenanceRoutes;
