import RouteConfigType from "../types/RouteConfigType";
import RouteSwitch from "../components/common/routes/RouteSwitch";
import React from "react";

const OrganizationOnboarding = React.lazy(() =>
  import("@pages/Onboarding/OrganizationOnboarding")
);
const OrganizationManagement = React.lazy(() =>
  import("@pages/Onboarding/OrganizationManagement")
);
const IntegrationManagement = React.lazy(() =>
  import("@pages/Onboarding/IntegrationManagement")
);
const FunctionalFlowManagement = React.lazy(() =>
  import("@pages/Onboarding/FunctionalFlowManagement")
);
const ComplianceManagement = React.lazy(() =>
  import("@pages/Onboarding/ComplianceManagement")
);
const TechnicalConfigurations = React.lazy(() =>
  import("@pages/Onboarding/TechnicalConfigurations")
);
const InterfaceManagement = React.lazy(() =>
  import("@pages/Onboarding/InterfaceManagement")
);
const EditInterfaceManagement = React.lazy(() =>
  import("@pages/Onboarding/InterfaceManagement/EditInterface")
);
const LicenseManagement = React.lazy(() =>
  import("@pages/Onboarding/LicenseManagement")
);
const AddNewLicense = React.lazy(() =>
  import("@pages/Onboarding/LicenseManagement/AddNewLicense")
);
const EditLicense = React.lazy(() =>
  import("@pages/Onboarding/LicenseManagement/EditLicense")
);
const EditOrganization = React.lazy(() =>
  import("@pages/Onboarding/OrganizationManagement/Container")
);
const EditFunctionalFlow = React.lazy(() =>
  import("@pages/Onboarding/FunctionalFlowManagement/EditFunctionalFlow")
);
const EditCompliance = React.lazy(() =>
  import("@pages/Onboarding/ComplianceManagement/EditCompliance")
);
const EditIntegrationManagement = React.lazy(() =>
  import("@pages/Onboarding/IntegrationManagement/EditIntegration")
);

const onboardingRoutes: RouteConfigType[] = [
  {
    component: OrganizationOnboarding,
    path: "/",
    title: "Organization Onboarding",
    exact: true,
    guard: [],
    useInBreadcrumbs: false,
  },
  {
    component: RouteSwitch,
    path: "/onboarding/license-management",
    title: "License Management",
    exact: false,
    guard: [],
    useInBreadcrumbs: true,
    routes: [
      {
        component: AddNewLicense,
        path: "/onboarding/license-management/create",
        title: "Add New License",
        exact: true,
        guard: [],
        useInBreadcrumbs: true,
      },
      {
        component: EditLicense,
        path: "/onboarding/license-management/edit/:id",
        title: "Edit License",
        exact: true,
        guard: [],
        useInBreadcrumbs: true,
      },
      {
        component: LicenseManagement,
        path: "/onboarding/license-management",
        title: "License Management",
        exact: true,
        guard: [],
        useInBreadcrumbs: false,
      },
    ],
  },
  {
    component: RouteSwitch,
    path: "/onboarding/organization-management",
    title: "Organization Management",
    exact: false,
    guard: [],
    useInBreadcrumbs: true,
    routes: [
      {
        component: EditOrganization,
        path: "/onboarding/organization-management/edit/:id",
        title: "Edit Organization",
        exact: false,
        guard: [],
        useInBreadcrumbs: true,
      },
      {
        component: OrganizationManagement,
        path: "/onboarding/organization-management",
        title: "Organization Management",
        exact: false,
        guard: [],
        useInBreadcrumbs: false,
      },
    ],
  },
  {
    component: RouteSwitch,
    path: "/onboarding/integration-management",
    title: "Integration Management",
    exact: false,
    guard: [],
    useInBreadcrumbs: true,
    routes: [
      {
        component: EditIntegrationManagement,
        path: "/onboarding/integration-management/edit/:id",
        title: "Edit Integration",
        exact: false,
        guard: [],
        useInBreadcrumbs: true,
      },
      {
        component: IntegrationManagement,
        path: "/onboarding/integration-management",
        title: "Integration Management",
        exact: false,
        guard: [],
        useInBreadcrumbs: false,
      },
    ],
  },
  {
    component: RouteSwitch,
    path: "/onboarding/flow-management",
    title: "Functional Flow Management",
    exact: false,
    guard: [],
    useInBreadcrumbs: true,
    routes: [
      {
        component: EditFunctionalFlow,
        path: "/onboarding/flow-management/edit/:id",
        title: "Edit Functional Flows",
        exact: false,
        guard: [],
        useInBreadcrumbs: true,
      },
      {
        component: FunctionalFlowManagement,
        path: "/onboarding/flow-management",
        title: "Functional Flow Management",
        exact: false,
        guard: [],
        useInBreadcrumbs: false,
      },
    ],
  },
  {
    component: RouteSwitch,
    path: "/onboarding/compliance-management",
    title: "Compliance Management",
    exact: false,
    guard: [],
    useInBreadcrumbs: true,
    routes: [
      {
        component: EditCompliance,
        path: "/onboarding/compliance-management/edit/:id",
        title: "Edit Compliance",
        exact: false,
        guard: [],
        useInBreadcrumbs: true,
      },
      {
        component: ComplianceManagement,
        path: "/onboarding/compliance-management",
        title: "Compliance Management",
        exact: false,
        guard: [],
        useInBreadcrumbs: false,
      },
    ],
  },
  {
    component: RouteSwitch,
    path: "/onboarding/technical-configurations",
    title: "Technical Configurations",
    exact: false,
    guard: [],
    useInBreadcrumbs: true,
    routes: [
      {
        component: TechnicalConfigurations,
        path: "/onboarding/technical-configurations/edit/:id",
        title: "Edit Technical Configurations",
        exact: false,
        guard: [],
        useInBreadcrumbs: true,
      },
      {
        component: TechnicalConfigurations,
        path: "/onboarding/technical-configurations",
        title: "Technical Configurations",
        exact: false,
        guard: [],
        useInBreadcrumbs: false,
      },
    ],
  },
  {
    component: RouteSwitch,
    path: "/onboarding/interface-management",
    title: "Interface Management",
    exact: false,
    guard: [],
    useInBreadcrumbs: true,
    routes: [
      {
        component: EditInterfaceManagement,
        path: "/onboarding/interface-management/edit/:id",
        title: "Edit Interface Management",
        exact: false,
        guard: [],
        useInBreadcrumbs: true,
      },
      {
        component: InterfaceManagement,
        path: "/onboarding/interface-management",
        title: "Interface Management",
        exact: false,
        guard: [],
        useInBreadcrumbs: false,
      },
    ],
  },
];

export default onboardingRoutes;
