import config, { getApiUrl } from "@app/utils/config";
import http, { httpVerbs } from "@app/utils/http";
import HTTPResponse from "@app/utils/http/interfaces/HttpResponse";
import {
  getParameterizedUrlWithSubPath,
  getParameterizedUrl,
} from "@app/utils";
import { OperationalServiceTypes } from "@iris/discovery.fe.client";

export const getChannelStatuses = (): Promise<HTTPResponse<any>> => {
  return Promise.resolve({
    status: 200,
    data: [
      { value: 1, name: "Active" },
      { value: 2, name: "Disabled" },
    ],
  });
};

export const loadChannelData = (channelId: string): Promise<any> => {
  //Genrating Path
  const folderStructurePath = `channels/${channelId}/folders`;
  return Promise.all([
    getChannel(channelId),
    getFolderDetails(folderStructurePath),
  ]);
};

export const getChannel = (channelId: any) => {
  return http({
    method: httpVerbs.GET,
    url: getParameterizedUrl(
      config.api[OperationalServiceTypes.MasterDataService].channelById,
      channelId
    ),
  });
};

export const createChannel = (channelData: any) => {
  return http({
    method: httpVerbs.POST,
    url: getApiUrl(
      config.api[OperationalServiceTypes.MasterDataService].createChannel
    ),
    data: channelData,
  });
};

export const updateChannel = (channelData: any, channelId: string) => {
  return http({
    method: httpVerbs.PUT,
    url: getParameterizedUrl(
      config.api[OperationalServiceTypes.MasterDataService].channelById,
      channelId
    ),
    data: channelData,
  });
};

export const checkChannelExsistance = (channelName: any, cancelToken: any) => {
  return http({
    method: httpVerbs.HEAD,
    url: getApiUrl(
      config.api[OperationalServiceTypes.MasterDataService]
        .checkChannelExsistance
    ),
    params: { name: channelName },
    cancelToken: cancelToken.token,
  });
};

export const getFolderDetails = (
  folderPath: string
): Promise<HTTPResponse<any>> => {
  return http({
    method: httpVerbs.GET,
    url: getParameterizedUrlWithSubPath(
      config.api[OperationalServiceTypes.MasterDataService].getFolders,
      folderPath
    ),
  });
};
