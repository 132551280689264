export const DATA_LOADING = "@@FUNCTIONAL_FLOW/DATA_LOADING";
export const DATA_LOAD_SUCCESS = "@@FUNCTIONAL_FLOW/DATA_LOAD_SUCCESS";
export const EDIT_FUNCTIONAL_FLOW = "@@FUNCTIONAL_FLOW/EDIT_FUNCTIONAL_FLOW";
export const DATA_LOAD_ERROR = "@@FUNCTIONAL_FLOW/GET_ERRORS";
export const CLEAR_ERRORS = "@@FUNCTIONAL_FLOW/CLEAR_ERRORS";
export const SAVE_FUNCTIONAL_FLOW = "@@FUNCTIONAL_FLOW/SAVE";
export const SAVE_SUCCESS_FUNCTIONAL_FLOW = "@@FUNCTIONAL_FLOW/SAVE_SUCCESS";
export const SAVE_FAIL_FUNCTIONAL_FLOW = "@@FUNCTIONAL_FLOW/SAVE_FAIL";
export const REDIRECT_FUNCTIONAL_FLOW = "@@FUNCTIONAL_FLOW/SAVE";
export const VERICAL_LOAD_SUCCESS = "@@FUNCTIONAL_FLOW/VERTICAL_LOAD_SUCCESS";
