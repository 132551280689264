import { WarningOutlined } from "@ant-design/icons";
import constants from "@app/constants";
import { Button } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./index.module.less";

export default () => {
  const history = useHistory();

  const redirectToPreviousPage = () => {
    window.history.back();
  };
  const redirectToDashboard = () => {
    history.push(constants.postSignedInRedirect);
  };

  return (
    <div className={styles.NotFoundPage}>
      <div>
        <WarningOutlined style={{ fontSize: "1500%", color: "#e2e222" }} />
        <h1>This link has no power here</h1>
        <p>
          Return to{" "}
          <Button onClick={redirectToPreviousPage}>Previous Page</Button> or go
          back to your <Button onClick={redirectToDashboard}>dashboard.</Button>
        </p>
      </div>
    </div>
  );
};
