import React, { Fragment, useEffect, useState } from "react";
import { Beforeunload } from "react-beforeunload";
import { Button, Spin } from "antd";
import { DataNode } from "rc-tree/lib/interface";
import { useForm } from "antd/lib/form/Form";
import { useParams } from "react-router";

import CancelChangesModal from "@app/utils/confirm/CancelChangesModal";
import ChannelContainer from "@app/features/Channel/ChannelContainer";
import PageContent from "@app/components/PageContent";
import PageTitle from "@app/components/PageTitle";
import logger from "@app/utils/logger";
import styles from "./index.module.less";
import { PromptOnload } from "@app/utils/confirm/onloadPrompt";
import {
  createChannel,
  updateChannel,
  loadChannelData,
} from "@app/api/channelService";
import {
  errorNotification,
  successNotification,
} from "@app/utils/antNotifications";
import { InfoCircleOutlined } from "@ant-design/icons";
import { IsFormDataChanged } from "@app/utils/forms/formDataEquality";
import {
  MAX_RETENTION_VALUE,
  MIN_RETENTION_VALUE,
  TreeDataNode,
} from "@app/components/FolderTree";

const MANAGE_CHANNELS_LANDING_PAGE = "/master-data/manage-channels";
const CHANNEL_EXSISTS_ERROR_CODE = 40001;

const mapFormData = (formValues: any, folders: TreeDataNode[]) => {
  return {
    ...formValues,
    folders: folders.map((folder) => {
      return {
        name: folder.folderName,
        subFolders: folder.children
          ? folder.children.map((subFolder) => {
              return {
                name: subFolder.folderName,
                retention: subFolder.retention,
              };
            })
          : [],
      };
    }),
  };
};

const mapUpdatedFormData = (formValues: any, folders: TreeDataNode[]) => {
  return {
    description: formValues.description,
    name: formValues.name,
    folders: folders.map((folder: any) => {
      const parentFolder: any = {
        name: folder.folderName,
        subFolders: folder.children
          ? folder.children.map((subFolder: any) => {
              const childParent: any = {
                name: subFolder.folderName,
                retention: subFolder.retention,
              };
              if (subFolder.preSaved) {
                childParent.id = parseInt(subFolder.key.split("_")[1]);
              }
              return childParent;
            })
          : [],
      };
      if (folder.preSaved) {
        parentFolder.id = parseInt(folder.key);
      }
      return parentFolder;
    }),
  };
};

const mapInitialFormData = (channel: any, folderDetails: any) => {
  return {
    description: channel.description,
    name: channel.name,
    folders: folderDetails.map((folder: any) => {
      return {
        id: folder.id,
        name: folder.name,
        subFolders: folder.subFolders
          ? folder.subFolders.map((subFolder: any) => {
              return { name: subFolder.name, id: subFolder.id };
            })
          : [],
      };
    }),
  };
};

export default (props: any) => {
  const { id } = useParams<any>();
  const [formChanged, setFormChanged] = useState(false);
  const [formCancel, setFormCancel] = useState(false);
  const [treeData, setTreeData] = useState<Array<TreeDataNode>>([]);
  const [form] = useForm();
  const [dataSaving, setDataSaving] = useState(false);
  const [displayNameError, setDisplayNameError] = useState(false);
  const [displayEmptyNameError, setDisplayEmptyNameError] = useState(false);

  const [channelDetails, setChannelDetails] = useState<any | undefined>();
  const [initialFormData, setInitialFormData] = useState<any | undefined>();
  const PageTitleName = () => {
    if (!id) {
      return props.title;
    } else {
      if (channelDetails) {
        return `${props.title} - ${channelDetails.name}`;
      } else {
        return props.title;
      }
    }
  };

  useEffect(() => {
    if (id) {
      loadChannelData(id).then((response: any) => {
        setChannelDetails({
          ...response[0]?.data,
          folders: response[1]?.data || [],
        });
        setInitialFormData(
          mapInitialFormData(response[0]?.data, response[1]?.data || [])
        );
      });
    }
  }, [id]);

  const handleOnChangeChannelForm = () => {
    setFormChanged(true);
  };

  const handleOnFinishChannelForm = () => {
      form.validateFields().then((values) => {
          setDataSaving(false);
          if (id) {
              const updatedFormData = mapUpdatedFormData(values, treeData);
              if (IsFormDataChanged(initialFormData, updatedFormData)) {
                  updateChannel(updatedFormData, id)
                      .then(() => {
                          setFormCancel(true);
                          setDataSaving(false);
                          props.history.push(MANAGE_CHANNELS_LANDING_PAGE);
                          successNotification([""], "Office updated successfully");
                      })
                      .catch((error) => {
                          setDataSaving(false);
                          errorNotification([""], "Failed to update Office");
                          logger.error("Master Data Module", "Updated Office", error);
                      });
              } else {
                  setFormCancel(true);
                  setDataSaving(false);
                  props.history.push(MANAGE_CHANNELS_LANDING_PAGE);
                  successNotification([""], "Nothing to Update");
              }
          } else {
              const mappedChannelData = mapFormData(values, treeData);
              createChannel(mappedChannelData)
                  .then(() => {
                      setFormCancel(true);
                      setDataSaving(false);
                      props.history.push(MANAGE_CHANNELS_LANDING_PAGE);
                      successNotification([""], "Office created successfully");
                  })
                  .catch((error) => {
                      setDataSaving(false);
                      errorNotification([""], "Failed to Create Office");
                      logger.error("Master Data Module", "Add Office", error);
                  });
          }
      });
    // if (treeData.length > 0) {
    //   if (displayNameError) {
    //     errorNotification([""], "Folder Name already exists in the same level");
    //     return;
    //   }
    //   if (displayEmptyNameError) {
    //     errorNotification([""], "Folder name cannot be empty");
    //     return;
    //   }
    //   if (!treeData.some((node) => node.children && !!node.children.length)) {
    //     errorNotification([""], "At least one sub folder is required");
    //     return;
    //   }
    //   const validateRetention = (nodes: TreeDataNode[]): boolean => {
    //     var res: boolean = true;
    //     for (let node of nodes) {
    //       if (node.children) {
    //         res = validateRetention(node.children);
    //       }
    //       if (node.retention === undefined) continue;
    //       if (node.retention === 0) continue;
    //       if (
    //         node.retention === null ||
    //         node.retention < MIN_RETENTION_VALUE ||
    //         node.retention > MAX_RETENTION_VALUE
    //       ) {
    //         res = false;
    //         return res;
    //       }
    //     }
    //     return res;
    //   };
    //   if (!validateRetention(treeData)) return;
    //
    // }
  };

  const onCancel = () => {
    if (formChanged) {
      setFormCancel(true);
    } else {
      props.history.push(MANAGE_CHANNELS_LANDING_PAGE);
    }
  };

  return (
    <Fragment>
      <PageTitle
        title={PageTitleName()}
        pageTitleClassName={styles.yjManageChannelTitle}
      ></PageTitle>
      <PageContent>
        {
          <CancelChangesModal
            visible={formCancel}
            title={id ? "Discard Changes" : "Discard Office"}
            content={
              id
                ? "The changes made to the office will be discarded.  Are you sure you want to proceed?"
                : "Your details will not be saved. Are you sure you want to discard the office?"
            }
            okText={id ? "Proceed" : "Discard"}
            cancelText="Cancel"
            onCancel={() => {
              setFormCancel(false);
            }}
            onOk={() => {
              props.history.push(MANAGE_CHANNELS_LANDING_PAGE);
            }}
          />
        }

        <Beforeunload onBeforeunload={(event) => event.preventDefault()} />
        {!formCancel && formChanged && (
          <PromptOnload isBlocking={true} isSaving={false} />
        )}
        <Spin tip="Saving..." spinning={dataSaving}>
          <ChannelContainer
            formRef={form}
            action={id ? "edit" : "save"}
            onChange={handleOnChangeChannelForm}
            onFinish={handleOnFinishChannelForm}
            channelDetailWithFolderStructure={
              channelDetails ? channelDetails : undefined
            }
            onFolderTreeUnmount={(treeDataResponse) => {
              setTreeData(treeDataResponse);
            }}
            onDupicateFolderAdded={(duplicateNameAdded) =>
              setDisplayNameError(duplicateNameAdded)
            }
            onEmptyFolderAdded={(emptyFolderAdded) => {
              setDisplayEmptyNameError(emptyFolderAdded);
            }}
          />
        </Spin>
        <div className={styles.stepButtonWrapper}>
          <div className={styles.stepInfoWrapper}>
            {/*<div className={styles.stepInfo}>*/}
            {/*  <InfoCircleOutlined />*/}
            {/*  Files can be placed only in sub folders. Please make sure to*/}
            {/*  create sub folders for all parent folders when creating the*/}
            {/*  channel*/}
            {/*</div>*/}
            <div className={styles.footerButtonWrapper}>
              <Button onClick={onCancel} type="default">
                Cancel
              </Button>
              <Button onClick={handleOnFinishChannelForm} type="primary">
                {id ? "Update" : "Create"}
              </Button>
            </div>
          </div>
        </div>
      </PageContent>
    </Fragment>
  );
};
