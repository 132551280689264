import RootPage from "../pages";
import RouteConfigType from "../types/RouteConfigType";
import onboardingRoutes from "./onboardingRoutes";
import fileAreaRoutes from "./fileAreaRoutes";
import masterDataRoutes from "./masterDataRoutes";
import UserManagementRoutes from "./userManagementRoutes";
import templateRoutes from "@app/routes/templateRoutes";
import React from "react";
import retentionRoutes from "@app/routes/retentionRoutes";
import documentMaintenanceRoutes from "@app/routes/documentMaintenanceRoutes";
const Forbidden = React.lazy(() => import("@pages/Common/Forbidden"));
const LoginPage = React.lazy(() => import("@pages/Login"));
const FileRequest = React.lazy(() => import("@pages/FileRequest"));
const UploadRequest = React.lazy(() => import("@pages/UploadRequest"));
const DashboardPage = React.lazy(() => import("@pages/Dashboard"));

const routes: RouteConfigType[] = [
  {
    path: "/",
    title: "Login",
    component: LoginPage,
    exact: true,
    guard: [],
  },
  {
    path: "/dashboard",
    title: "Dashboard",
    component: DashboardPage,
    exact: true,
    guard: [],
    useInBreadcrumbs: true,
  },
  {
    path: "/onboarding",
    title: "Organization Onboarding",
    component: RootPage,
    exact: false,
    guard: [],
    useInBreadcrumbs: true,
    routes: onboardingRoutes,
    disableBreadcrum: true,
  },
  {
    path: "/filearea",
    title: "FileArea",
    component: RootPage,
    exact: false,
    guard: [],
    useInBreadcrumbs: true,
    routes: fileAreaRoutes,
  },
  {
    path: "/master-data",
    title: "Master Data",
    component: RootPage,
    exact: false,
    guard: [],
    useInBreadcrumbs: true,
    disableBreadcrum: true,
    routes: masterDataRoutes,
  },
  {
    path: "/requestFiles/:id",
    title: "FileArea",
    component: FileRequest,
    exact: true,
    guard: [],
  },
  {
    path: "/requestFiles/:id/:securityKey/upload",
    title: "FileArea",
    component: UploadRequest,
    exact: true,
    guard: [],
  },
  {
    path: "/user-management",
    title: "User Management",
    component: RootPage,
    exact: false,
    guard: [],
    useInBreadcrumbs: true,
    routes: UserManagementRoutes,
    disableBreadcrum: true,
  },
  {
    path: "/template",
    title: "Template",
    component: RootPage,
    exact: false,
    guard: [],
    useInBreadcrumbs: true,
    disableBreadcrum: true,
    routes: templateRoutes,
  },
  {
    path: "/retention",
    title: "Retention",
    component: RootPage,
    exact: false,
    guard: [],
    useInBreadcrumbs: true,
    disableBreadcrum: true,
    routes: retentionRoutes,
  },
  {
    path: "/document-maintenance",
    title: "Document Maintenance 1",
    component: RootPage,
    exact: false,
    guard: [],
    useInBreadcrumbs: true,
    disableBreadcrum: true,
    routes: documentMaintenanceRoutes,
  },
  {
    path: "/forbidden",
    title: "",
    component: Forbidden,
    exact: true,
    guard: [],
    useInBreadcrumbs: false,
  },
];

export default routes;
