import { getUserNavigationMenu, getUserPermission } from "@app/api/userService";
import { SecureRoute, useOktaAuth } from "@okta/okta-react";
import { Skeleton } from "antd";
import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@app/redux/reducers/state";
import CommonLayout from "@app/layouts/CommonLayout";
import { LOGIN_SUCCESS, SET_BASE_URLS, SET_TENANT, } from "@app/redux/actionTypes/authActionTypes";
import AppError from "@app/components/AppError";
import logger from "@app/utils/logger";
import { setUserLoaded } from "@app/redux/actions/authActions";
import { HubConnection, HubConnectionBuilder, HubConnectionState, } from "@microsoft/signalr";
import config, { getApiUrl } from "@app/utils/config";
import { errorNotification, successNotification, } from "@app/utils/antNotifications";
import { updateGridHasUpdates } from "@app/redux/actions/gridsActions";
import { DiscoveryClient } from "../../../../";
import Constants from "@app/constants";
import TenantSelection from "../../TenatSelection";
import { OperationalServiceTypes, } from "@iris/discovery.fe.client";
import { setUserPermission } from "@app/redux/actions/userManagementActions";

const RoutesWithSubRoutes = (route: any) => {
  const dispatch = useDispatch();
  const { authState, oktaAuth } = useOktaAuth();
  const [apiError, setApiError] = useState("");
  const menu = useSelector((state: RootState) => state.auth.menuItems);
  const [connection, setConnection] = useState<null | HubConnection>(null);
  const { endPoints, tenant } = useSelector((state: RootState) => state.auth);

  const fetchUserData = async () => {

    try {
      const permissions = await getUserPermission();
      dispatch(setUserPermission(permissions));
    } catch (e) {
      console.log('fetchUserData ======', e);
      if (!e.response) setApiError("ERR_NETWORK");
      else setApiError(e.message);
    }
  };

  useEffect(() => {
    if (connection) {
      connection.on("ReceiveMessage", (subject, message) => {
        if (subject % 2 === 0) {
          errorNotification([""], message);
        } else {
          successNotification([""], message);
        }
        dispatch(updateGridHasUpdates(true));
      });
      connection
        .start()
        .then(() => {
          logger.info(
            "YJ Generic",
            "Push Notification",
            "started push notification"
          );
        })
        .catch((error) =>
          logger.error("YJ Generic", "Push Notification", error)
        );
    }
  }, [connection]);

  useEffect(() => {
    let tids = (authState?.accessToken?.claims.tid as Array<string>) ?? [];

    if (tids.length === 1 && !tenant)
      dispatch({ type: SET_TENANT, payload: tids[0] });

    if (authState?.isAuthenticated && tenant) {
      if (!tids.find((e) => e == tenant))
        dispatch({ type: SET_TENANT, payload: null });

      if (endPoints === null) {
        DiscoveryClient.getBaseURL(Constants.tokenKey, {
          TenantCode: tenant,
        })
          .then((discoveryEndpoints) => {
            if (discoveryEndpoints)
              dispatch({
                type: SET_BASE_URLS,
                payload: discoveryEndpoints,
              });
          })
          .catch(() => {
            errorNotification([""], "Discovery Service Error");
          });
      } else {
        if (menu.length === 0) {
          if (
            connection != null &&
            connection.state === HubConnectionState.Connected
          ) {
            connection.stop();
          }
          if (authState?.isAuthenticated) {
            oktaAuth
              .getUser()
              .then((user) => dispatch(setUserLoaded(true, user)));
            const connect = new HubConnectionBuilder()
              .withUrl(
                getApiUrl(
                  config.api[
                    OperationalServiceTypes.NotificationService
                  ].pushNotification.slice(
                    config.api[
                      OperationalServiceTypes.NotificationService
                    ].pushNotification.indexOf("api")
                  ),
                  OperationalServiceTypes.NotificationService
                ),
                {
                  accessTokenFactory: () =>
                    authState?.accessToken?.accessToken ?? "",
                }
              )
              .withAutomaticReconnect()
              .build();
            setConnection(connect);
          }
          getUserNavigationMenu()
            .then(async (response) => {
              dispatch({
                type: LOGIN_SUCCESS,
                payload: {
                  access_token: oktaAuth.getAccessToken(),
                  menuItems: response.data,
                },
              });
            })
            .catch((e) => {
              if (!e.response) setApiError("ERR_NETWORK");
              else setApiError(e.message);
            });

          fetchUserData()
        }
      }
    }
  }, [authState?.isAuthenticated, tenant, endPoints]);

  return (
    <SecureRoute
      path={route.path}
      render={(props) => {
        if (!tenant && authState?.isAuthenticated)
          return (
            <CommonLayout>
              <TenantSelection />
            </CommonLayout>
          );

        if (endPoints === null) return <Skeleton />;
        console.warn('location Router with sub route', route)
        return menu.length > 0 || !authState?.isAuthenticated ? (
          <SecureRoute
            path={route.path}
            render={(props) => (
              <Suspense fallback={<Skeleton />}>
                <route.component
                  {...props}
                  title={route.title}
                  routes={route.routes}
                />
              </Suspense>
            )}
          />
        ) : apiError === "" ? (
          <Skeleton />
        ) : (
          <CommonLayout>
            <AppError
              message={
                apiError === "ERR_NETWORK" || apiError == "500" ? "An error occurred while processing your request" : "You do not have permission to access"
              }
              showContactAdmin={apiError !== "ERR_NETWORK"}
              showLogoutButton={false}
            />
          </CommonLayout>
        );
      }}
    />
  );
};

export default RoutesWithSubRoutes;
