import MenuConfigType from "@app/types/MenuConfigType";
import { FileTextOutlined } from "@ant-design/icons";

const fileAreaMenuConfig: MenuConfigType = {
  path: "/filearea/",
  title: "File Area",
  key: "FileArea",
  icon: FileTextOutlined,
  guard: [],
};

export default fileAreaMenuConfig;
