import { Button, Steps, Skeleton } from "antd";
import { FormInstance } from "antd/es/form/Form";
import React, { forwardRef, Fragment, useState } from "react";

import WizardConfigType from "@app/types/WizardConfigType";
import styles from "./index.module.less";
import { formActionType } from "@app/utils";

type UserGroupWizardProps = {
  history: any;
  permissonList: any;
  steps: WizardConfigType[];
  formRef: FormInstance;
  formLoaded: boolean;
  onSave?: (processedFormValues: any) => void;
  onCancel?: () => void;
  action: formActionType;
};
const { Step } = Steps;

export default forwardRef((props: UserGroupWizardProps, ref) => {
  const STEP_BASIC_USER_GROUP_INFO = 0;
  const STEP_PERMISSIONS = 1;

  const [currentStep, setCurrentStep] = useState(STEP_BASIC_USER_GROUP_INFO);
  const [processedFormValues, setProcessedFormValues] = useState<any>();

  const onSubmitClick = () => {
    if (currentStep !== STEP_PERMISSIONS) {
      props.formRef.validateFields().then((v) => {
        setProcessedFormValues(props.formRef.getFieldsValue());
        setCurrentStep(currentStep + 1);
      });
    } else {
      props.onSave && props.onSave(processedFormValues);
    }
  };

  return (
    <Fragment>
      <div className={"yjStepper"}>
        <Steps current={currentStep}>
          {props.steps?.map((item) => (
            <Step key={item.key} title={<span>{item.name}</span>} />
          ))}
        </Steps>
      </div>
      {props.formLoaded ? (
        <div className={styles.stepContentWrapper}>
          <div className={styles.stepContentHeader}>
            {currentStep === STEP_BASIC_USER_GROUP_INFO
              ? "BASIC INFORMATION"
              : "FILE AREA PERMISSIONS"}
          </div>
          {props.steps && props.steps[currentStep].content}
        </div>
      ) : (
        <Skeleton active />
      )}
      <div className={styles.stepButtonWrapper}>
        <div>
          <Button
            hidden={currentStep === STEP_BASIC_USER_GROUP_INFO}
            className={styles.yjWizardPageTransitionButton}
            type="primary"
            htmlType="submit"
            onClick={() => {
              if (currentStep !== STEP_BASIC_USER_GROUP_INFO) {
                setCurrentStep(currentStep - 1);
              }
            }}
          >
            Back
          </Button>
        </div>

        <div className={styles.stepButtonGroupWrapper}>
          <Button
            type="default"
            onClick={() => {
              props.history.push("/user-management/user-groups");
              if (props.onCancel) {
                props.onCancel();
              }
            }}
          >
            Cancel
          </Button>

          <Button
            type="primary"
            onClick={onSubmitClick}
            className={styles.yjWizardPageTransitionButton}
          >
            {currentStep === props.steps?.length - 1
              ? `${props.action === "edit" ? "Update" : "Create"}`
              : "Save and Continue"}
          </Button>
        </div>
      </div>
    </Fragment>
  );
});
