import React from "react";
import { Layout } from "antd";

const { Content } = Layout;

type MasterLayoutPageContent = {
  children?: any;
};

export default (props: MasterLayoutPageContent) => {
  return (
    <Content
      style={{
        maxHeight: "87.5vh",
        overflowX: "hidden",
        overflowY: "auto",
        padding: "25px",
      }}
    >
      {props.children}
    </Content>
  );
};
